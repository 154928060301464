import { useState } from "react";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import { EAADivider } from "../Components/EAADivider";
import { deleteData, patchData } from "../feathers";
import { UserProvider } from "../Persistence/UserContext";
import { CaseUserCard } from "./CaseUserCard";
import { CaseUsersForm, emptyCaseUser } from "./CaseUsersForm";
import { ToastError } from "../Utils/ToastError";
import { useCurrentUser } from "../Persistence/CurrentUserContext";
import { Carousel } from "primereact/carousel";
import { ENUM_ICONS } from "../Components/Icons";


export const CaseUsersSheet = ({ eaaCase, handleOnCaseUserPatched, hideDivider }) => {
	const currentUser = useCurrentUser();
	const [caseUser, setCaseUser] = useState();
	const [displayCaseUserDialog, setDisplayCaseUserDialog] = useState(false);
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const [displayConfirmDialogResponsible, setDisplayConfirmDialogResponisble] = useState(false);
	const [formHasErrors, setFormHasErrors] = useState(true);
	const [toastParams, setToastParams] = useState(null);

	const handleCaseUsersUpdate = async () => {
		caseUser.eaaCaseId = eaaCase.id;
		caseUser.userId = caseUser.caseuser.id

		await patchData('case-users', caseUser).then(async (patchedCaseUser) => {
			handleOnCaseUserPatched(patchedCaseUser);
		}).catch((error) => {
			setToastParams({ title: 'Berater*In konnte nicht ' + (caseUser.id ? 'geändert' : 'angelegt') + ' werden', message: error.message })
		});
		setDisplayCaseUserDialog(false);
	}

	const handleCaseUserDelete = async () => {
		await deleteData('case-users', caseUser.id).then(async () => {
			handleOnCaseUserPatched();
			setCaseUser(null)
		}).catch((error) => {
			setToastParams({ title: 'Daten konnten nicht gelöscht werden', message: error.message })
		});
	}

	const handleCaseUserEdit = (_caseUser) => {
		if (_caseUser) {
			_caseUser.selectionUser = _caseUser.caseuser.displayname
			_caseUser.isResponsible = _caseUser.roleType.alias === 'R';
			setCaseUser(_caseUser)
		} else {
			setCaseUser({ ...emptyCaseUser })
		}

		setDisplayCaseUserDialog(true)
	}

	const responsiveOptions = [
		{ breakpoint: '1024px', numVisible: 3, numScroll: 3 },
		{ breakpoint: '600px', numVisible: 2, numScroll: 2 },
		{ breakpoint: '480px', numVisible: 1, numScroll: 1 }
	];

	const confirmCaseUserDelete = async (caseUser) => {
		setCaseUser(caseUser);
		setDisplayConfirmDialog(true)
	}

	const confirmCaseUserUpdate = async () => {

		if (caseUser.isResponsible && caseUser.roleType.alias !== 'R') {
			/** Wenn der zu ändernde Case-user der Verantwortliche war und jetzt nicht mehr
			* -> Abfrage ob der Verantwortliche wirklich 'geändert' werden soll */
			setDisplayConfirmDialogResponisble(true)
		} else {
			await handleCaseUsersUpdate()
		}
	}

	const renderHeader = () => {
		return <div className={(hideDivider ? 'flex' : 'hidden') + ' flex-column'}>
			<div className='flex font-bold mb-2'>Operative Rollen</div>
			<div className={'flex justify-content-between border-bottom-1'}>
				<div className='flex font-medium  mb-2 '>Bearbeiter*innen</div>
				<div className='flex '>
					<div className={currentUser.permissions === 'READER' || (eaaCase && eaaCase.status && eaaCase.status.alias === 'INACTIVE') ? 'hidden' : 'flex w-1rem open-icon'} onClick={() => { handleCaseUserEdit(null); setDisplayCaseUserDialog(true) }}><i className={ENUM_ICONS.PLUS_CIRCLE} /></div>
				</div>
			</div>
		</div>
	}

	const renderCaseUserCard = (caseUser) => {
		return !caseUser ? '' : <CaseUserCard key={'caseusercard_' + caseUser.id} disabled={eaaCase && eaaCase.status && eaaCase.status.alias === 'INACTIVE'} caseUser={caseUser} onDeleteHandler={confirmCaseUserDelete} onEditHandler={handleCaseUserEdit} />
	}

	const renderCaseUsers = (caseUsers) => {
		return !caseUsers ? 'keine' : <div className='flex flex-column gap-1 w-full'> {caseUsers.map((caseUser) => {
			return <CaseUserCard key={'caseusercard_' + caseUser.id} caseUser={caseUser} onDeleteHandler={confirmCaseUserDelete} onEditHandler={handleCaseUserEdit} disabled={eaaCase && eaaCase.status && eaaCase.status.alias === 'INACTIVE'} />
		})}</div>
	}

	return (
		<>
			<ToastError toastParams={toastParams} />
			<ConfirmDialog title='Bearbeiter*in entfernen' message={`Sind Sie sicher,\nPerson ${caseUser && caseUser.caseuser ? caseUser.caseuser.lastname + ', ' + caseUser.caseuser.firstname : ''}\n diese Bearbeiter*in zu entfernen?`} labelOk='Ja'
				handleOnClick={handleCaseUserDelete} displayConfirmDialog={displayConfirmDialog} setDisplayConfirmDialog={setDisplayConfirmDialog} />
			<ConfirmDialog title='Bearbeiter*in als Verantwortliche/r entfernen' message={`Sind Sie sicher,\nPerson ${caseUser && caseUser.caseuser ? caseUser.caseuser.lastname + ', ' + caseUser.caseuser.firstname : ''}\n als Verantwortliche/r Bearbeiter*in zu entfernen?`} labelOk='Ja'
				handleOnClick={handleCaseUsersUpdate} displayConfirmDialog={displayConfirmDialogResponsible} setDisplayConfirmDialog={setDisplayConfirmDialogResponisble} />

			<Dialog header='Operative Rollen' visible={displayCaseUserDialog} onHide={() => setDisplayCaseUserDialog(false)} id="dialog-caseuser" className='eaa-inputdialog' >
				<div className='flex font-light mb-3 p-0 '>{'Hier können Sie die administrative Rolle des Falles ändern.'}</div>
				<UserProvider><CaseUsersForm caseUser={caseUser} setFormHasErrors={setFormHasErrors} eaaFormHasError={false} setCaseUser={setCaseUser} setShowDialog={() => { }} setDisplayCaseUserDialog={setDisplayCaseUserDialog} eaaCase={eaaCase} /></UserProvider>
				<div className="flex justify-content-end gap-2 mr-5 my-2">
					<Button className='button-cancel' label="Abbrechen" onClick={() => { setDisplayCaseUserDialog(false) }} />
					<Button onClick={confirmCaseUserUpdate} disabled={formHasErrors} className="flex " label={caseUser && caseUser.id ? 'Ändern' : 'Neu hinzufügen'} />
				</div>
			</Dialog>
			<Card className="caseuserssheet card-sheet" header={renderHeader}>
				<EAADivider className={(hideDivider ? 'hidden ' : '') + 'm-0 p-1'} icon={ENUM_ICONS.PLUS} label='Neue/r Bearbeiter*in' onClickHandler={() => handleCaseUserEdit(null)} />
				{hideDivider ?
					renderCaseUsers(eaaCase.caseUsers) :
					<Carousel className='w-full mt-10 ' showIndicators={false} value={eaaCase.caseUsers} numVisible={3} numScroll={3} itemTemplate={renderCaseUserCard} responsiveOptions={responsiveOptions} />
				}
			</Card>
		</>
	)

}