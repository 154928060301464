import { useState, useEffect, useRef } from 'react';
import client, { checkPassword, patchData, createPassword, logout } from '../feathers';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'; // To use confirmDialog method
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import './ChangePassword.scss'

export const ChangePassword = ({ user, currentUser, hrMode, hideDialog, hideLogo }) => {

	const toast = useRef(null);
	const [oldPassword, setOldPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [repeatPassword, setRepeatPassword] = useState("");
	const [forceChangePassword, setForceChangePassword] = useState(false)
	const [showPassword, setShowPassword] = useState(false);
	const [error, setError] = useState('');

	useEffect(() => {
		const generatePassword = async () => {
			const password = createPassword(false);
			setNewPassword(password);
			setRepeatPassword(password);
		}

		if (hrMode) {
			setForceChangePassword(true);
			setShowPassword(true);
			generatePassword();
		}
	}, [hrMode])

	const changeUserPassword = async () => {
		setError('')
		if (!hrMode && !checkPassword(user, oldPassword)) {
			showToast('bestehendes Passwort falsch')
		} else if (!hrMode && newPassword === oldPassword) {
			showToast('Neues Passwort darf NICHT gleich dem bestehenden Passwort sein')
		} else if (!hrMode && ("" + newPassword).toUpperCase() === ("" + oldPassword).toUpperCase()) {
			showToast('Neues Passwort muss neue Zeichenkombinationen besitzen')
		} else if (newPassword !== repeatPassword) {
			showToast('Passwortwiederholung ist falsch!')
		} else if (("" + newPassword).length < 8) {
			showToast('Neues Passwort muss mindestens 8 Zeichen haben')
		} else {
			await patchData('users', { id: user.id, version: currentUser.version, password: newPassword, forceChangePassword: forceChangePassword ? 1 : 0 }, currentUser).then(() => {
				const confirm = () => {
					confirmDialog({
						message: (hrMode ? 'Passwort von ' + user.displayname + ' wurde geändert' : 'Bitte melden Sie sich mit dem neuen Passwort erneut an.'),
						header: 'Sie können starten.',
						icon: 'fa fa-exclamation-triangle ',
						acceptLabel: 'Ok',
						rejectClassName: 'hidden',
						accept: () => {
							if (!hrMode) {
								client.logout()
							} else {
								hideDialog()
							}
						},
						onHide: () => { if (!hrMode) { client.logout() } }
					});
				}
				confirm();
			});
		}
	}
	const showToast = (message) => {
		toast.current.show({ severity: 'warn', summary: 'Passwortänderung fehlgeschlagen', detail: message, sticky: true });
	}
	return <div id="login-container" className='flex h-full'>
		<Toast ref={toast} />
		<ConfirmDialog />
		<div className={"flex justify-content-center mx-auto border-round-md " + (hideLogo ? 'my-4' : 'my-auto shadow-2 ')} style={{ height: (hideLogo ? 'auto' : '600px'), width: '1080px' }} >

			<div className={hideLogo ? 'hidden' : 'flex justify-content-center w-6 h-full'}>
				<img src="../icons/logo_eaa-tool.png" className='flex align-items-center w-full my-auto m-4' />
			</div>
			<div className={"flex justify-content-center text-white h-full " + (hideLogo ? 'border-round-md w-full' : 'color-background-blue border-round-right-md w-6')}>
				<div className='mx-auto my-auto w-20rem'>
					<div className="hidden" >
						<p style={{ color: 'red' }} >{error}</p>
					</div>
					<div className="p-d-flex p-jc-center" style={{ width: '100%' }}>
						<div className="">
							<div className={hrMode ? 'hidden' : "field flex flex-column "} >
								<label htmlFor="oldPassword" className={hideLogo ? 'text-color' : "p-0"} >Altes Passwort</label>
								<Password type='text' autoFocus={true} tabIndex={1} autoComplete="nope" feedback={false} placeholder="Altes Passwort" id="oldPassword" className={'' + (oldPassword === '' || !oldPassword ? 'p-invalid' : '')} onChange={ev => setOldPassword(ev.target.value)} toggleMask />
							</div>
							<div className="field flex flex-column " >
								<label htmlFor="newPassword" className={hideLogo ? 'text-color' : "p-0"} >Neues Passwort</label>
								<Password autoComplete="nope" tabIndex={2} value={newPassword} feedback={false} placeholder="Neues Passwort" id="newPassword" className={'' + (newPassword === '' || !repeatPassword ? 'p-invalid' : '')} onChange={ev => setNewPassword(ev.target.value)} toggleMask />
							</div>
							<div className="field flex flex-column" >
								<label htmlFor="repeatPassword" className={hideLogo ? 'text-color' : "p-0"} >Neues Passwort wiederholen</label>
								<Password autoComplete="nope" tabIndex={3} value={repeatPassword} feedback={false} placeholder="Passwort wiederholen" id="repeatPassword" className={'' + (repeatPassword === '' || !repeatPassword ? 'p-invalid' : '')} onChange={ev => setRepeatPassword(ev.target.value)} toggleMask />
							</div>
							<div className={(hrMode ? 'flex' : 'hidden') + " formgrid grid p-2"}>
								<Checkbox inputId="forceChangePasswordbinary" tabIndex={4} onChange={e => setForceChangePassword(e.checked)} checked={forceChangePassword} />
								<label htmlFor="forceChangePassword" className={(hideLogo ? 'text-color' : "") + ' ml-2 text-sm'} >Benutzer muss Passwort ändern</label>
							</div>
							<div className="flex justify-content-end gap-2" style={{ marginTop: '30px' }}>
								<Button className="button-cancel" label="Abbrechen" tabIndex={6} onClick={() => { hideLogo ? hideDialog() : logout() }} />
								<Button className={hideLogo ? '' : 'p-button-success'} tabIndex={5} onClick={() => changeUserPassword()} label="Passwort ändern" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div >

}