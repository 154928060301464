import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { ConfirmDialog } from "../Components/ConfirmDialog";
import { EditDialog } from "../Components/Dialogs/EditDialog";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { findData, patchData } from "../feathers";
import { ROUTES } from "../Navigation/Routes";
import { useBreadcrumbsUpdate } from "../Persistence/Breadcrumbs";
import { useCurrentUser, useCurrentUserIsAdmin } from "../Persistence/CurrentUserContext";
import { logDefault } from "../Utils/logger";
import { ToastError } from "../Utils/ToastError";
import { emptyAssignUser, RequestDistributionAssignUserForm, validateAssignUserForm } from "./RequestDistributionAssignUserForm";
import { RequestDistributionUserCard } from "./RequestDistributionUserCard";


const CLASSNAME = 'RequestDistributionUsersSheet'
export const RequestDistributionUsersSheet = ({ requestDistribution }) => {
	const currentUser = useCurrentUser();
	const isAdmin = useCurrentUserIsAdmin();
	const updateBreadcrumbs = useBreadcrumbsUpdate();
	const [toastParams, setToastParams] = useState(null);
	const [possibleUsers, setPossibleUsers] = useState([]);
	const [displayAssignUserDialog, setDisplayAssignUserDialog] = useState(false);
	const [displayConfirmAssignDialog, setDisplayConfirmAssignDialog] = useState(false);

	useEffect(() => {
		if (requestDistribution) {
			const { regions, taskareas } = requestDistribution
			if (regions.length === 0 && taskareas.length === 0) {
				setPossibleUsers([]);
			} else {
				const conditions = taskareas.length > 0 ? [{ '$taskareas.id$': { $in: taskareas.map((t => t.id)) } }] : [];
				if (regions.length > 0) {
					conditions.push({ '$regions.id$': { $in: regions.map((r => r.id)) } })
				}
				const queryUsers = conditions.length > 0 ? { $and: conditions } : {}
				findData('users', queryUsers).then((users) => {
					logDefault(CLASSNAME, 'info', 'users: ' + users.length, queryUsers)
					setPossibleUsers(users)
				})
			}

		}
	}, [requestDistribution])

	const renderHeader = () => {
		return <div className='flex flex-column color-lessblack'>
			<div className='flex font-bold mb-2'>Berater*in zuweisen</div>
			<div className='flex flex-column '>
				<div className='flex font-medium border-bottom-1 my-2 '>Schnellzuweisung </div>
				<div className="flex justify-content-between mx-4 my-2 " >
					<Button key={uuidv4()} onClick={() => { setDisplayConfirmAssignDialog(true) }} type="button" className="flex-order-0" label='Mir zuweisen' />
					<Button key={uuidv4()} onClick={() => { setDisplayAssignUserDialog(true) }} disabled={!isAdmin} className="flex-order-1 " label='Jemand zuweisen' />
				</div>
				<div className='flex font-medium border-bottom-1 my-2 '>Vorschläge </div>
			</div>
		</div>
	}

	const assignUser = async (user) => {
		logDefault(CLASSNAME, 'info', 'assignUser: ' + user.id, (requestDistribution !== null))
		if (requestDistribution) {
			await patchData(ENUM_SERVICES.REQUESTDISTRIBUTIONS, { ...requestDistribution, attachments: [], html_message: '', newAssignedUserId: user.id }).then((requestDistribution) => {
				updateBreadcrumbs({ home: { icon: ROUTES.REQUESTDISTRIBUTION.icon }, items: [{ label: ROUTES.REQUESTDISTRIBUTION.label, route: ROUTES.REQUESTDISTRIBUTION.route }] })
			}).catch((error) => {
				setToastParams({ title: 'Berater*In konnte nicht zugeordnet werden', message: error.message })
			});
		}
	}

	const formikAssignUser = useFormik({
		initialValues: { ...emptyAssignUser },
		validate: validateAssignUserForm,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	return (
		<div className='flex flex-column  px-3 '>
			<ToastError toastParams={toastParams} />
			<ConfirmDialog title='Aufgabe mir zuweisen' message={`<span>Wollen Sie diese Aufgabe<br/><b>sich selbst</b><br/>zuzuweisen?</span>`} labelOk='Ja'
				handleOnClick={() => assignUser(currentUser)} displayConfirmDialog={displayConfirmAssignDialog} setDisplayConfirmDialog={setDisplayConfirmAssignDialog} />

			<EditDialog
				form={<RequestDistributionAssignUserForm formik={formikAssignUser} setPossibleUsers={setPossibleUsers} key='assign-user-form' />}
				formik={formikAssignUser}
				patchForm={() => assignUser(formikAssignUser.values.newAssignUser)}
				header='Jemanden zuweisen'
				message='<p>Hier können Sie die Anfrage einem Benutzer zuweisen.</p>'
				labelButtonOk={'Zuweisen'}
				setDisplayDialog={setDisplayAssignUserDialog}
				displayRegionDialog={displayAssignUserDialog}
			/>
			{renderHeader()}
			{possibleUsers.length === 0 ? <div className="flex justify-content-center text-center w-full">{requestDistribution.regions.length === 0 && requestDistribution.taskareas.length === 0 ? 'Keine Regionen und Aufgabenbereiche zugeordnet' : 'Keinen Treffer gefunden'}</div> :
				<Card key={uuidv4()} className="requestdistributionsheet  card-sheet  mb-3">
					<div className='flex flex-column gap-2'>
						{possibleUsers.map(user => <RequestDistributionUserCard key={uuidv4()} user={user} onAssignUser={assignUser} />)}
					</div>
				</Card>}
		</div>
	)
}