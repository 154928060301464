import { useFormik } from "formik";
import { Button } from "primereact/button";
import { useState } from 'react';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { ENUM_ICONS } from "../Components/Icons";
import { TemplateDataView } from "../Components/TemplateDataView";
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { useCurrentUserIsAdmin } from '../Persistence/CurrentUserContext';
import { ENUM_DATAVIEWS } from '../Persistence/DataViewFilterContext';
import { useOrganizationsList } from '../Persistence/OrganizationsContext';
import { logDefault } from "../Utils/logger";
import { getData, patchData } from "../feathers";
import { OrganizationForm, emptyOrganization, validateOrganization } from './OrginizationForm';
import { OverlayPanelMenu } from "../Components/OverlayPanelMenu";


const CLASSNAME = 'OrganizationDataview'
const ENUM_DATAVIEWS_VALUE = ENUM_DATAVIEWS.ORGANIZATIONS
export const OrganizationDataview = ({ handleStatusChange }) => {
	const isAdmin = useCurrentUserIsAdmin();
	const configurationsList = useConfigurationsList();
	const organizationsList = useOrganizationsList();
	const [displayOrganizationDialog, setDisplayOrganizationDialog] = useState(false);

	const patchOrganization = async () => {
		const org = { ...formikOrganization.values, type: formikOrganization.values.orgtype.alias, ignoreInStatistics: formikOrganization.values.ignoreInStatistics ? 1 : 0 };
		await patchData('organizations', org).then(() => {
			setDisplayOrganizationDialog(false)
		})
	}

	/** Organizations */
	const formikOrganization = useFormik({
		initialValues: { ...emptyOrganization },
		validate: validateOrganization,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const onEditOrganization = async (id) => {
		await getData('organizations', id).then((organization) => {
			organization.orgtype = configurationsList.OrganizationTypesList.find(entry => entry.alias === organization.type)
			organization.ignoreInStatistics = (organization.ignoreInStatistics === 1)
			logDefault(CLASSNAME, 'info', 'onEditOrganization organization:', organization)
			formikOrganization.setValues(organization);
			setDisplayOrganizationDialog(true)
		})
	}

	const renderContextMenu = (rowData) => {
		const items = [{ label: 'Organisation bearbeiten', icon: ENUM_ICONS.PENCIL, command: () => { onEditOrganization(rowData.id) } },
		{ label: rowData.active ? 'Inaktiv setzen' : 'Aktiv setzen', icon: (rowData.active ? ENUM_ICONS.DEACTIVATE : ENUM_ICONS.REACTIVATE), command: () => { handleStatusChange(rowData, 'organizations', 'Organisation') } }]

		return <div>
			<OverlayPanelMenu items={items} />
		</div>
	}

	const renderOrganizationType = (row) => {
		const type = configurationsList.OrganizationTypesList.find(entry => entry.alias === row.type)
		return type ? type.display : row.type
	}

	const columnsOrganizations = [
		{ field: "ignoreInStatistics", sortable: true, filter: "ignoreInStatistics", header: "Statistik", className: 'w-10rem', body: 'renderListIcon' },
		{ field: "name", sortable: true, filter: "name", header: "Name", className: '' },
		{ field: "type", sortable: true, filter: "type", header: "Typ", className: 'w-20rem', body: renderOrganizationType },
		{ field: "active", sortable: true, filter: "active", header: "Status", className: 'w-10rem', body: 'renderTag' },
		{ className: 'w-3rem', body: renderContextMenu },
	]

	return <div className='flex flex-column gap-2'>
		<div className='flex justify-content-end'>
			<Button className='' disabled={!isAdmin} label='Neue Organisation anlegen' onClick={() => { formikOrganization.setValues({ ...emptyOrganization }); setDisplayOrganizationDialog(true) }} />
		</div>
		<TemplateDataView key='datatable-organizations' scrollHeight={'calc(100vh - 240px)'} classNameDataTable='flex w-full' dataTableList={organizationsList} columns={columnsOrganizations} ENUM_DATAVIEWS_VALUE={ENUM_DATAVIEWS_VALUE} />
		<EditDialog
			form={<OrganizationForm formik={formikOrganization} key='organization-form' />}
			formik={formikOrganization}
			patchForm={patchOrganization}
			header={formikOrganization.values.id ? 'Organiation ändern' : 'Organiation neu anlegen'}
			message={formikOrganization.values.id ? 'Hier können sie die Organiation: ' + formikOrganization.values.name + ' ändern.' : 'Hier können sie eine Organiation neu anlegen'}
			setDisplayDialog={setDisplayOrganizationDialog}
			displayRegionDialog={displayOrganizationDialog}
		/>
	</div>
}