import { format, parseISO } from "date-fns";
import { Card } from "primereact/card";
import { ENUM_ICONS } from "../Components/Icons";
import { ROUTES } from "../Navigation/Routes";
import { useBreadcrumbsUpdate } from "../Persistence/Breadcrumbs";
import { useCurrentUser } from "../Persistence/CurrentUserContext"

export const JobOpeningCaseSheet = ({ jobOpening }) => {

	const currentUser = useCurrentUser();
	const updateBreadcrumbs = useBreadcrumbsUpdate();

	const renderCaseSheetHeader = () => {
		return <div className='flex flex-column'>
			<div className='flex font-bold mb-2'>Falldaten</div>
			{jobOpening.eaaCase ?
				<div className='flex justify-content-between border-bottom-1'>
					<div className='flex font-medium  mb-2 '>{jobOpening.eaaCase.name}</div>
					<div className={currentUser.permissions === 'READER' ? 'hidden' : 'flex '}>
						<div className='flex w-1rem' onClick={() => {
							const route = ROUTES['EAACASES']
							updateBreadcrumbs({ home: { icon: route.icon }, items: [{ label: route.label, route: route.route }, { label: '', route: route.route + '/' + jobOpening.eaaCase.id }] })
						}}>
							<i className={ENUM_ICONS.EYE} />
						</div>
					</div>
				</div> : ''}
		</div>
	}

	const renderCaseSheet = () => {
		const eaaCase = (jobOpening && jobOpening.eaaCase) ? jobOpening.eaaCase : null;
		return <Card className="jobopeningsheet card-sheet" header={renderCaseSheetHeader}>
			{!eaaCase ? 'keinem Fall zugeordnet' : <div>
				<div className={eaaCase.name ? 'grid' : 'hidden'}>
					<div className="col-fixed">Fall-ID</div>
					<div className="col">{eaaCase.caseDocId}</div>
				</div>
				<div className={eaaCase.date ? 'grid' : 'hidden'}>
					<div className="col-fixed">Falldatum</div>
					<div className="col">{format(parseISO(eaaCase.date), 'yyyy-MM-dd')}</div>
				</div>
				<div className={eaaCase.responsible !== null ? 'grid' : 'hidden'}>
					<div className="col-fixed">Verantwortliche*r</div>
					<div className="col">{eaaCase.responsible.displayname}</div>
				</div>
				<div className={eaaCase.caseCreator ? 'grid' : 'hidden'}>
					<div className="col-fixed">Ersteller*in</div>
					<div className="col">{eaaCase.caseCreator.displayname}</div>
				</div>
				<div className={eaaCase.initiatedBy !== null ? 'grid' : 'hidden'}>
					<div className="col-fixed">Initiiert durch</div>
					<div className="col">{eaaCase.initiatedBy.display}</div>
				</div>
				<div className={eaaCase.status ? 'grid' : 'hidden'}>
					<div className="col-fixed">Status</div>
					<div className="col">{eaaCase.status.display}</div>
				</div>
			</div>}
		</Card>
	}

	return renderCaseSheet()
}