export enum ENUM_SERVICES {
	COMPANIES = 'companies',
	EAACASES = 'eaa-cases',
	JOBPROFILES = 'job-profiles',
	PROVIDERACTIVITIES = 'provider-activities',
	REQUESTDISTRIBUTIONS = 'request-distributions',
	USERS = 'users',
	REMINDERS = 'reminders',
	UTILS = 'utils',
	TASKAREAS = 'taskareas',
	REGIONS = 'regions',
	COMPANYCONTACTPERSONS = 'company-contact-persons'
};