import { format, parse, parseISO } from 'date-fns';
import { useFormik } from 'formik';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from "react";
import { CaseEventsForm } from '../CaseEvents/CaseEventsForm';
import { CasePersonForm } from '../CasePersons/CasePersonForm';
import { CaseUsersSheet } from '../CaseUsers/CaseUsersSheet';
import { CalendarChangesChecked } from '../Components/CalendarChangesChecked';
import { DropdownChangesChecked } from '../Components/DropdownChangesChecked';
import { EditorChangesChecked } from '../Components/EditorChangesChecked';
import { ENUM_ICONS } from '../Components/Icons';
import { InputChangesChecked } from "../Components/InputChangesChecked";
import UnsafedChangesDialog from "../Components/UnsafedChangesDialog";
import { getData, patchData } from '../feathers';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { useBreadcrumbsAdd } from '../Persistence/Breadcrumbs';
import { CasePersonsProvider } from '../Persistence/CasePersonsContext';
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { useCurrentUser } from '../Persistence/CurrentUserContext';
import { logDefault } from '../Utils/logger';
import { useCallbackPrompt } from "../Utils/useCallbackPrompt";
import { useMediaQuery } from '../Utils/useMediaQuery';
import { EAACaseCreateCompanyData } from "./EAACaseCreateCompanyData";

const CLASSNAME = 'EAACaseCreate';
export const EAACaseCreate = () => {

	const currentUser = useCurrentUser();
	const configurationsList = useConfigurationsList();
	const addBreadcrumb = useBreadcrumbsAdd();
	const [screenSize] = useMediaQuery();
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const [activeIndex, setActiveIndex] = useState([0, 1])
	const [editmode, setEditmode] = useState(true);
	const [showDialog, setShowDialog] = useState(false);
	const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog)
	const [eaaCase, setEaaCase] = useState();
	const [caseEvent, setCaseEvent] = useState();
	const [hasErrors, setHasErrors] = useState(false)
	const [toastParams, setToastParams] = useState(null);

	const updateEaaCase = async () => {
		if (eaaCase && eaaCase.id) {
			await getData('eaa-cases', eaaCase.id).then((eaaCase) => {
				logDefault(CLASSNAME, 'info', 'EAACaseCreate updateEaaCase:', eaaCase)
				formik.setValues(eaaCase);
			})
		}
	}

	const patchCaseEvent = async () => {
		if (caseEvent) {
			caseEvent.eaaCaseId = eaaCase.id
			caseEvent.eventDate = parse(format(caseEvent.eventTime, 'HH:mm'), 'HH:mm', caseEvent.eventDate)
			await patchData('case-events', caseEvent).then(async (caseEvent) => {
				caseEvent.eventDate = parseISO(caseEvent.eventDate)
				logDefault(CLASSNAME, 'info', 'patchCaseEvent', caseEvent);
				setCaseEvent(caseEvent);
				await updateEaaCase();
				setShowDialog(false);
			}).catch((error) => {
				setToastParams({ title: 'Kontakt konnte nicht angelegt werden', message: error.message })
			})
		}
	}

	const patchCasePerson = async () => {
		if (eaaCase.casePerson) {
			await patchData('case-persons', eaaCase.casePerson).then(async (casePerson) => {
				eaaCase.casePersonId = casePerson.id
				await patchEaaCase();
			}).catch((error) => {
				setToastParams({ title: 'Fallperson konnte nicht angelegt werden', message: error.message })
			})
		}
	}

	const patchEaaCase = async () => {
		if (eaaCase.company) {
			await patchData('companies', eaaCase.company).then((company) => {
				eaaCase.companyId = company.id
			})
		}
		if (caseEvent) {
			eaaCase.caseEvents = [caseEvent]
		}

		await patchData('eaa-cases', eaaCase).then((newEaaCase) => {
			formik.setValues(newEaaCase);
			setShowDialog(false);
		})
	}

	const formik = useFormik({
		initialValues: {
			id: null, version: 1, active: 1, caseDocId: '', name: '', description: '', date: '', initiatedBy: '', status: '', documentLocation: '', supportCaseId: '', company: null, casePerson: null, updateUser: null, caseUsers: [], caseEvents: []
		},
		validate: (data) => {
			let errors = {};

			if (!data.name) {
				errors.name = 'Name ist ein Pflichtfeld';
			}
			if (!data.company || !data.company.name) {
				errors.companyId = 'Unternehmen ist ein Pflichtfeld';
			}
			if (!data.status) {
				errors.status = 'Status ist ein Pflichtfeld';
			}
			if (!data.initiatedBy) {
				errors.initiatedBy = 'Initiiert ist ein Pflichtfeld';
			}
			if (!data.date) {
				errors.date = 'Datum ist ein Pflichtfeld';
			}
			if (!data.description) {
				errors.description = 'Beschreibung ist ein Pflichtfeld';
			}
			setHasErrors(Object.keys(errors).length !== 0)
			setEaaCase(data)
			return errors;
		},
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true
	});

	useEffect(() => {
		if (configurationsList.CaseStatusList) {
			formik.setFieldValue('status', configurationsList.CaseStatusList.find(entry => entry.alias === 'OPEN'))
		}
	}, [configurationsList])

	const onUpdateCaseEvent = (caseEvent) => {
		caseEvent.eventDate = parse(format(caseEvent.eventTime, 'HH:mm'), 'HH:mm', caseEvent.eventDate)
		setCaseEvent(caseEvent)
	}

	const renderHeaderAccordionTabCasePerson = () => {
		return <div className='flex justify-content-between'>
			<div className='flex'>{formik.values.casePerson ? 'Person - ' + formik.values.casePerson.personId : "Person"}</div>
			<div className='flex  w-2rem'>{!formik.values.id && formik.values.casePerson && formik.values.casePerson.id ? <i className={ENUM_ICONS.TRASH} onClick={(event) => { event.stopPropagation(); setDisplayConfirmDialog(true) }}></i> : ''}</div>
		</div>
	}

	const renderHeaderAccordionTabCompany = () => {
		return <div className='flex justify-content-between'>
			<div className='flex'>{formik.values.company ? "Unternehmen - " + formik.values.company.name : "Unternehmen (Pflichtangabe)"}</div>
			<div className='flex  w-2rem'>{!formik.values.id && formik.values.company && formik.values.company.id ? <i className={ENUM_ICONS.TRASH} onClick={(event) => { event.stopPropagation(); setDisplayConfirmDialog(true) }}></i> : ''}</div>
		</div>
	}

	const renderHeaderAccordionTabCaseEvent = () => {
		if (!caseEvent || !caseEvent.id) {
			return 'Kontakt'
		}
		logDefault(CLASSNAME, 'info', 'renderHeaderAccordionTabCaseEvent', caseEvent);
		const eventType = caseEvent.eventType && typeof caseEvent.eventType === 'object' && caseEvent.eventType.constructor === Object ? caseEvent.eventType.display : caseEvent.eventType
		const eventDate = caseEvent.eventDate ? format(caseEvent.eventDate, 'dd.MM.yyyy') : '';
		const eventTime = caseEvent.eventDate ? format(caseEvent.eventDate, 'HH:mm') : '';

		return <div className="flex w-full justify-content-between m-0">
			<div className='flex w-25rem  gap-4'>
				<div className="flex font-bold w-8rem m-0 ">{`${eventType}`}</div>
				<div className="flex font-bold m-0 gap-2"><i className={ENUM_ICONS.CALENDAR} /> {` ${eventDate}`}</div>
				<div className="flex font-bold m-0 gap-2"><i className={ENUM_ICONS.CLOCK} /> {` ${eventTime}`}</div>
			</div>
			<div className='flex flex-grow-1 '>
				<div className="flex font-bold m-0 gap-2"> {`Ersteller*in: ${currentUser.displayname}`}</div>
			</div>
		</div>
	}

	const renderHeaderAccordionTabCaseUsers = () => {
		const caseUsers = !formik.values.caseUsers ? '' : formik.values.caseUsers.map((entry) => entry.caseuser.lastname + ', ' + entry.caseuser.firstname).join(' | ')
		return <div className='flex'>
			<div className='flex'>{"Operative Rollen im Fall" + (caseUsers !== '' ? ' - ' + caseUsers : '')}</div>
		</div>
	}

	return (
		<div className="mx-auto eaa-maincontent grid m-0">
			<div className='col overflow-auto' style={{ height: (screenSize[1] - 70) + 'px' }}>
				<Dialog header='Unternehmen entfernen?' visible={displayConfirmDialog} onHide={() => setDisplayConfirmDialog(false)} id="dialog-confirm" className='eaa-inputdialog w-20rem' >
					<div className='flex font-light my-3 p-0 '>Sind Sie sicher, die Verknüpfung zum Unternehmen zu entfernen?</div>
					<div className="flex flex-row card-container blue-container flex-row-reverse mx-4 my-2">
						<Button type="button" className="flex-order-1 button-cancel" label='Abbrechen' onClick={() => setDisplayConfirmDialog(false)} />
						<Button onClick={() => { formik.setFieldValue('company', null); setActiveIndex([0]); setDisplayConfirmDialog(false) }} className="flex-order-0 ml-3 " label='Entfernen' />
					</div>

				</Dialog>
				<UnsafedChangesDialog
					// @ts-ignore
					showDialog={showPrompt}
					confirmNavigation={confirmNavigation}
					cancelNavigation={cancelNavigation}
				/>

				<Accordion id='accordion-eaacasecreate' multiple activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
					<AccordionTab header={renderHeaderAccordionTabCompany()}>
						<EAACaseCreateCompanyData eaaCase={eaaCase} handleSave={(company) => { formik.setFieldValue('company', company) }} setActiveIndex={setActiveIndex} setShowDialog={setShowDialog} />
					</AccordionTab>
					<AccordionTab header={formik.values.name ? 'Fallspezifische Felder - ' + (formik.values.name.length > 50 ? formik.values.name.substring(0, 50) + '...' : formik.values.name) : 'Fallspezifische Felder (Pflichtangaben)'}>
						<div className='m-3'>
							<div className="formgrid grid">
								<InputChangesChecked id='name' tabIndex={100} type="text" formik={formik} editmode={editmode} label='Kurzbeschreibung / Titel' setShowDialog={setShowDialog} />
							</div>
							<div className="formgrid grid">
								<CalendarChangesChecked id='date' tabIndex={101} formik={formik} editmode={editmode} label='Datum' setShowDialog={setShowDialog} maxDate={new Date()} />
								<div className="field col mr-3" />
							</div>
							<div className="formgrid grid">
								<DropdownChangesChecked tabIndex={102} editmode={editmode} label={'Status'} id={'status'} itemLabel={'display'} formik={formik} options={configurationsList.CaseStatusList} setShowDialog={setShowDialog} handleChange={formik.handleChange} />
								<div className="field col mr-3" />
							</div>
							<div className="formgrid grid">
								<DropdownChangesChecked tabIndex={103} editmode={editmode} label={'Inititiert durch'} id={'initiatedBy'} itemLabel={'display'} formik={formik} options={configurationsList.InitiatedByTypesList} setShowDialog={setShowDialog} handleChange={formik.handleChange} />
								<InputChangesChecked tabIndex={104} id='documentLocation' label='Dateipfad für Dokumentation' type="text" formik={formik} editmode={editmode} setShowDialog={setShowDialog} />
							</div>
							<div className="formgrid grid">
								<EditorChangesChecked id='description' formik={formik} required={true} value={formik.values.description} setValue={(value) => formik.setFieldValue('description', value)} label='Beschreibung' setShowDialog={setShowDialog} />
							</div>
							<div className={editmode ? 'card' : 'hidden'}>
								<div className="flex flex-row card-container blue-container flex-row-reverse m-2">
									<Button tabIndex={106} onClick={() => { patchEaaCase(); setActiveIndex([2]); }} disabled={hasErrors} className="flex-order-0 ml-3" label={'Speichern und Weiter'} />
								</div>
							</div>
						</div>
					</AccordionTab>
					<AccordionTab disabled={!eaaCase || !eaaCase.companyId || !eaaCase.id} header={renderHeaderAccordionTabCasePerson()}>
						<CasePersonsProvider>
							<div className='m-3'>
								<CasePersonForm eaaCase={eaaCase} setShowDialog={setShowDialog} handleCasePersonUpdate={(casePerson) => { formik.setFieldValue('casePerson', casePerson) }} />
							</div>
						</CasePersonsProvider>
						<div className={'m-3'}>
							<div className="flex flex-row card-container blue-container flex-row-reverse m-5">
								<Button onClick={() => { patchCasePerson(); setActiveIndex([3]); }} disabled={!eaaCase || !eaaCase.casePerson || !eaaCase.casePerson.personId} className="flex-order-0 ml-3 " label={'Speichern und Weiter'} />
								<Button onClick={() => { setActiveIndex([3]) }} className={eaaCase && eaaCase.casePersonId ? 'hidden' : 'flex-order-0 ml-3'} label={'Überspringen'} />
							</div>
						</div>
					</AccordionTab>
					<AccordionTab disabled={!eaaCase || !eaaCase.companyId || !eaaCase.id} header={renderHeaderAccordionTabCaseUsers()}>
						<div className='m-3'>
							<CaseUsersSheet eaaCase={eaaCase} handleOnCaseUserPatched={updateEaaCase} hideDivider={false} />
							<div className="flex flex-row card-container blue-container flex-row-reverse mr-4">
								<Button onClick={() => { setActiveIndex([4]) }} className="flex-order-0 ml-3 " label={eaaCase && eaaCase.caseUsers ? 'Weiter' : 'Überspringen'} />
							</div>
						</div>
					</AccordionTab>
					<AccordionTab disabled={!eaaCase || !eaaCase.companyId || !eaaCase.id} header={renderHeaderAccordionTabCaseEvent()} >
						<div className='m-3'>
							<CaseEventsForm caseEvent={caseEvent} handleCaseEventUpdate={onUpdateCaseEvent} eaaFormHasError={hasErrors} setShowDialog={setShowDialog} setFormHasErrors={() => { }} />
							<div className={'card m-3'}>
								<div className="flex flex-row card-container blue-container flex-row-reverse m-3">
									<Button
										disabled={!caseEvent || !caseEvent.eventDate || !caseEvent.description || !caseEvent.eventType}
										onClick={async () => { await patchCaseEvent(); setActiveIndex([]) }}
										className="flex-order-0 ml-3 "
										label={'Speichern und Weiter'} />
									<Button onClick={() => { setActiveIndex([]) }} className={caseEvent && caseEvent.id ? 'hidden' : 'flex-order-0 ml-3'} label={'Überspringen'} />
								</div>
							</div>
						</div>
					</AccordionTab>
				</Accordion>
				<div className={'card'}>
					<div className="flex flex-row card-container blue-container flex-row-reverse m-3">
						<Button onClick={async () => { await patchEaaCase(); addBreadcrumb(`${formik.values.name}`, `${ENUM_ROUTES.EAACASES}/` + formik.values.id); }} disabled={hasErrors} className="mr-3 " label={'Anlage des neuen Falls abschließen'} />
					</div>
				</div>
			</div>
		</div>


	)
}
