import { format } from "date-fns";
import { Column } from 'primereact/column';
import { ContextMenu } from "primereact/contextmenu";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { useCallback, useEffect, useRef, useState } from 'react';
import { useBreadcrumbsAdd } from "../Persistence/Breadcrumbs";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { useDataViewFilter, useDataViewFilterUpdate, useDataViewPagination, useDataViewPaginationUpdate, useDataViewSort, useDataViewSortUpdate } from "../Persistence/DataViewFilterContext";
import { useRegionsList } from "../Persistence/RegionsContext";
import { useTaskareasList } from "../Persistence/TaskareasContext";
import { logDefault } from "../Utils/logger";
import { DateFilterElement } from "./FilterTemplates/DateFilterElement";
import { FilterTemplateMultiSelect } from "./FilterTemplates/FilterTemplateMultiSelect";
import { MultiSelectFilterElement } from "./FilterTemplates/MultiSelectFilterElement";
import { ENUM_ICONS } from "./Icons";
import { OpenEye } from "./OpenEye";
import { ResponsibleMultiSelect } from "./ResponsibleMultiSelect";

const CLASSNAME = 'TemplateDataView'
export const TemplateDataView = ({ dataTableList, columns, showOpenLinkColumn, ENUM_DATAVIEWS_VALUE, ROUTE, classNameDataTable, scrollHeight }) => {
	const addBreadcrumb = useBreadcrumbsAdd();
	const configurationsList = useConfigurationsList();
	const dataViewFilter = useDataViewFilter();
	const setDataViewFilter = useDataViewFilterUpdate();
	const dataViewSort = useDataViewSort();
	const setDataViewSort = useDataViewSortUpdate();
	const dataViewPagination = useDataViewPagination();
	const setDataViewPagination = useDataViewPaginationUpdate();
	const regionsList = useRegionsList();
	const taskareasList = useTaskareasList();

	const [paginationFirst, setPaginationFirst] = useState(dataViewPagination[ENUM_DATAVIEWS_VALUE].paginationFirst);
	const [paginationRows, setPaginationRows] = useState(dataViewPagination[ENUM_DATAVIEWS_VALUE].paginationRows);
	const [currentPage, setCurrentPage] = useState(dataViewPagination[ENUM_DATAVIEWS_VALUE].currentPage);
	const [selectedRow, setSelectedRow] = useState();
	const [sortValue, setSortValue] = useState(dataViewSort[ENUM_DATAVIEWS_VALUE]);
	const [multiSortMeta, setMultiSortMeta] = useState(dataViewSort[ENUM_DATAVIEWS_VALUE]);
	const [filters, setFilters] = useState(dataViewFilter[ENUM_DATAVIEWS_VALUE]);
	const [filterValues, setFilterValues] = useState();
	const [mounted, setMounted] = useState(false);
	const [initPagination, setInitPagination] = useState(false);
	const [renderFunctions, setRenderFunctions] = useState();
	const [listFilterElements, setListFilterElements] = useState();
	const cm = useRef(null);

	useEffect(() => {
		//value.filter(element => filter.includes(element)).length
	}, [])

	useEffect(() => {
		setListFilterElements({ 'listFilter': listFilterElement, 'dateFilter': dateFilterElement, 'singleTagFilter': singleTagFilterElement, 'multiTagFilterElement': multiTagFilterElement, 'responsibleFilterElement': responsibleFilterElement, 'multiSelectFilterElement': multiSelectFilterElement })
		setRenderFunctions({ 'renderDate': renderDate, 'renderList': renderList, 'renderListIcon': renderListIcon, 'renderTag': renderTag })
	}, [])

	useEffect(() => {
		if (configurationsList && configurationsList.RegionsList && configurationsList.TaskareasList) {
			logDefault(CLASSNAME, 'info', 'RegionsList ', (configurationsList.RegionsList ? configurationsList.RegionsList.length : null))
			logDefault(CLASSNAME, 'info', 'TaskareasList ', (configurationsList.TaskareasList ? configurationsList.TaskareasList.length : null))
			const filterValues = {}
			columns.forEach((column) => {
				filterValues[column.filter] = null;
			})
			setFilterValues(filterValues);
			setMounted(true)
		}
	}, [configurationsList, taskareasList, regionsList])

	const renderOpenLink = (rowData) => {
		return <OpenEye ROUTE={ROUTE} id={rowData.id} />
	}

	const onChangeFilter = (e) => {
		logDefault(CLASSNAME, 'info', 'onChangeFilter ', e.filters)
		const filters = e.filters;
		setDataViewFilter(ENUM_DATAVIEWS_VALUE, filters);
		setFilters(filters);
	}
	const onChangeSort = (e) => {
		setMultiSortMeta(e.multiSortMeta)
		setDataViewSort(ENUM_DATAVIEWS_VALUE, e.multiSortMeta)
	}
	const onChangePaginator = (event) => {
		if (!initPagination) {
			event.first = dataViewPagination[ENUM_DATAVIEWS_VALUE].paginationFirst
		}
		setInitPagination(true);
		setPaginationFirst(event.first);
		setPaginationRows(event.rows);
		setCurrentPage(event.page);
		logDefault(CLASSNAME, 'info', ENUM_DATAVIEWS_VALUE + ' onChangePaginator: paginationFirst:' + paginationFirst, event)

		setDataViewPagination(ENUM_DATAVIEWS_VALUE, { paginationFirst: event.first, paginationRows: event.rows, currentPage: event.page })
	}

	const sortColumn = (event) => {
		let data = [...dataTableList];
		data.sort((data1, data2) => {
			const value1 = data1[event.field];
			const value2 = data2[event.field];
			let result = null;

			if (value1 == null && value2 != null)
				result = -1;
			else if (value1 != null && value2 == null)
				result = 1;
			else if (value1 == null && value2 == null)
				result = 0;
			else if (typeof value1.alias === 'string' && typeof value2.alias === 'string')
				result = value1.alias.localeCompare(value2.alias, undefined, { numeric: true });
			else
				result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

			return (event.order * result);
		});
		return data;
	}

	const menuModel = [
		{ label: 'Öffnen', icon: ENUM_ICONS.EYE, command: () => addBreadcrumb(``, `${ROUTE}/` + selectedRow.id) },
		{ label: 'In neuem Tab öffnen', icon: ENUM_ICONS.EYE, command: () => window.open(window.location.href + '/' + selectedRow.id, '_blank') },
	];

	const setFilterValue = (field, value) => {
		const _filterValues = { ...filterValues };
		_filterValues[field] = value;
		setFilterValues(_filterValues);
	}

	const listValueTemplate = (option, placeholder) => {
		return <div style={{ minHeight: '21px' }}>{option && option.icon ? <i className={option.icon + ' px-1 w-2rem '} /> : ' '}{option && option.onlyIcon ? ' ' : (option ? option.datatable : placeholder)}</div>
	}

	const listItemTemplate = (option) => {
		return <div>{option.icon ? <i className={option.icon + ' pr-1 w-2rem'} /> : ''}{option.datatable}</div>
	}

	const listFilterElement = useCallback((options) => {
		const listOptions = options.filterModel ? configurationsList[options.filterModel.filterListName] : [];
		const className = options.filterModel && options.filterModel.filterElementClassName ? options.filterModel.filterElementClassName : '';
		const placeholder = options.filterModel && options.filterModel.filterPlaceholder ? options.filterModel.filterPlaceholder : null;
		logDefault(CLASSNAME, 'info', ENUM_DATAVIEWS_VALUE + ' listFilterElement placeholdeer' + placeholder + ' options:', options)
		return !listOptions ? null : <Dropdown value={options.value} className={className} panelClassName='p-2' optionLabel="datatable" optionValue="alias" options={listOptions} onChange={(e) => { options.filterApplyCallback(e.value) }} itemTemplate={listItemTemplate} valueTemplate={(option) => listValueTemplate(option, placeholder)} placeholder={placeholder} />;

	}, [configurationsList])

	const renderTagFilter = (option) => { return <Tag className={"mr-2 text-white px-3 color-tag-" + option.color} rounded value={option.datatable} /> }
	const singleTagFilterElement = (options) => {
		const listOptions = options.filterModel ? configurationsList[options.filterModel.filterListName] : [];
		const className = options.filterModel && options.filterModel.filterElementClassName ? options.filterModel.filterElementClassName : '';
		const placeholder = options.filterModel && options.filterModel.filterPlaceholder ? options.filterModel.filterPlaceholder : null;
		logDefault(CLASSNAME, 'info', ENUM_DATAVIEWS_VALUE + ' singleTagFilterElement listOptions' + className, listOptions)
		return <Dropdown value={options.value} className={className} optionLabel="datatable" optionValue="alias" options={listOptions} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={renderTagFilter} placeholder={placeholder} />;
	}
	const multiTagFilterElement = (options) => {
		return <FilterTemplateMultiSelect options={options} key={'FilterTemplateMultiSelect_' + options.field} />
	}
	const multiSelectFilterElement = (options) => {
		return <MultiSelectFilterElement options={options} key={'MultiSelectFilter_' + options.field} />
	}
	const responsibleFilterElement = (options) => {
		return <ResponsibleMultiSelect value={options.value} onChange={(value) => { options.filterApplyCallback(value) }} />
	}
	const dateFilterElement = (options) => {
		return <DateFilterElement options={options} maxDate={filters[options.field].maxDate} minDate={filters[options.field].minDate} />
	}

	const renderTag = (rowData, column) => {
		const values = Array.isArray(rowData[column.field]) ? rowData[column.field] : [rowData[column.field]]
		const list = configurationsList[filters[column.field].filterListName] ? configurationsList[filters[column.field].filterListName] : [];
		logDefault(CLASSNAME, 'info', ENUM_DATAVIEWS_VALUE + ' renderTag list:' + filters[column.field].filterListName + ' count:' + list.length)
		return values.map(value => {
			const alias = value.constructor === Object ? value.alias : value;
			const option = list.find(entry => entry.alias === alias);
			logDefault(CLASSNAME, 'info', ENUM_DATAVIEWS_VALUE + ' renderTag:' + alias, option)
			const style = option && option.backcolor ? { color: '#' + option.color, backgroundColor: '#' + option.backcolor, borderColor: '#' + option.color, borderStyle: 'solid', borderWidth: option.backcolor.toLowerCase() === 'ffffff' ? '1px' : '0px' } : {};
			const className = 'px-3 mx-1' + (option && option.backcolor ? '' : (option ? 'text-white color-tag-' + option.color : ''));
			return <Tag key={'tag_' + rowData.id + '_' + alias} style={style} className={className} rounded value={option ? (option.datatable || option.alias) : '---'} />
		})
	}

	const renderDate = (rowData, column) => { return (rowData[column.field] ? format(rowData[column.field], 'yyyy-MM-dd') : '---') }
	const renderListIcon = (rowData, column) => {
		const list = configurationsList[filters[column.field].filterListName] ? configurationsList[filters[column.field].filterListName] : [];
		const entry = list.find(e => rowData[column.field] === e.alias)
		return entry ? <i className={'flex w-full justify-content-center ' + entry.icon} /> : rowData[column.field];
	}
	const renderList = (rowData, column) => {
		const list = configurationsList[filters[column.field].filterListName] ? configurationsList[filters[column.field].filterListName] : [];
		const entry = list.find(e => rowData[column.field] === e.alias)
		return entry ? entry.datatable : rowData[column.field];
	}
	const paginatorTemplate = {
		layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
		'RowsPerPageDropdown': (options) => {
			const dropdownOptions = [
				{ label: 15, value: 15 },
				{ label: 30, value: 30 },
				{ label: 50, value: 50 },
				{ label: 100, value: 100 },
				{ label: 200, value: 200 }
			];

			return (
				<>
					<span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Einträge pro Seite: </span>
					<Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
				</>
			);
		},
		'CurrentPageReport': (options) => {
			return (<div style={{ marginLeft: '20px', color: 'var(--text-color)', userSelect: 'none', width: '200px', textAlign: 'center' }}>
				<span >
					{options.first} - {options.last} von {options.totalRecords}
				</span>
				<span style={{ display: 'none', marginLeft: '20px', marginRight: '20px', color: 'var(--text-color)', userSelect: 'none', width: '30px', textAlign: 'center' }}>
					Seite {currentPage + 1}
				</span>
			</div>
			)
		}
	};
	return (
		<div className={'mx-auto select-none ' + (classNameDataTable || 'eaa-dataView')}>
			<div className="card flex md:inline-flex w-full" >
				<ContextMenu model={menuModel} ref={cm} onHide={() => setSelectedRow(null)} />
				{!mounted ? '' :
					<DataTable emptyMessage='keine Einträge gefunden' scrollHeight={scrollHeight || 'calc(100vh - 220px)'} className='w-full' showGridlines size="small" responsiveLayout="scroll" dataKey="id" stripedRows
						value={dataTableList} selection={selectedRow}
						removableSort onSort={onChangeSort} sortMode="multiple" multiSortMeta={multiSortMeta}
						filters={filters} filterDisplay='row' onFilter={onChangeFilter}
						paginator paginatorTemplate={paginatorTemplate} first={paginationFirst} rows={paginationRows} onPage={onChangePaginator}
						contextMenuSelection={selectedRow}
						onContextMenuSelectionChange={e => setSelectedRow(e.value)}
						onContextMenu={e => { if (showOpenLinkColumn) { cm.current.show(e.originalEvent) } }}>

						{/** Tabellenspalten */
							/**console.log('columns:', columns, filters)*/}
						{columns.map((column) => {
							return (<Column
								key={ENUM_DATAVIEWS_VALUE + '-' + column.field}
								className={column.className}
								sortable={column.sortable}
								filter={column.filter}
								filterField={column.filterField}
								field={column.field}
								filterPlaceholder={filters[column.field] ? filters[column.field].filterPlaceholder : null}
								filterElement={filters[column.field] && filters[column.field].filterElement ? listFilterElements[filters[column.field].filterElement] : null}
								onFilterClear={() => setFilterValue(column.filter, null)}
								body={renderFunctions[column.body] ? renderFunctions[column.body] : column.body}
								header={column.header}
								align={column.align}
								showFilterMenu={false}>
							</Column>)
						})}
						{showOpenLinkColumn ? <Column className='open-icon justify-content-center w-3rem p-0' body={renderOpenLink}></Column> : null}
					</DataTable>}
			</div>
		</div>
	)
}
