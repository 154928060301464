import { useBreadcrumbs } from "../Persistence/Breadcrumbs";
import { ENUM_AWESOMEICON, ENUM_ICONS, Icon } from "../Components/Icons";

const navitemClassName = 'p-ripple flex flex-row  gap-2 align-items-center cursor-pointer p-3 lg:justify-content-start text-600 border-left-2 border-transparent hover:border-300 transition-duration-150 transition-colors'

export const NavigationEntry = ({ icon, route, label, onSidebarClick, showLabel, awesomeIcon }) => {

	const breadcrumbs = useBreadcrumbs();

	const renderEntry = () => {
		const className = " flex mr-2 lg:mr-0 lg:text-2xl w-2rem justify-content-center " + (breadcrumbs && breadcrumbs.home && breadcrumbs.home.icon === icon ? 'color-text-blue' : 'text-base');
		return <>
			<a className={navitemClassName} onClick={() => (onSidebarClick(route, icon, label))}  >
				{awesomeIcon ? <Icon ENUM_AWESOMEICON={awesomeIcon} className={className} /> : <i className={icon + className} ></i>}
				<div className={(showLabel ? 'flex inline' : 'hidden') + " font-semibold " + (breadcrumbs && breadcrumbs.home && breadcrumbs.home.icon === icon ? 'color-text-blue' : 'text-base')}>{label}</div>
			</a>
		</>
	}

	return (
		<>
			<li>
				{renderEntry()}
			</li>
		</>)
}