import { Card } from "primereact/card"
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { CasePersonsProvider } from "../Persistence/CasePersonsContext";
import { CasePersonForm } from "./CasePersonForm";
import { patchData } from "../feathers";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import { useCurrentUser } from "../Persistence/CurrentUserContext";
import { ENUM_ICONS } from "../Components/Icons";

export const CasePersonSheet = ({ eaaCase, patchEaaCase }) => {
	const currentUser = useCurrentUser();
	const [displayCasePersonDialog, setDisplayCasePersonDialog] = useState(false);
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const [casePerson, setCasePerson] = useState();
	const [casePersonDialog, setCasePersonDialog] = useState();

	useEffect(() => {
		if (eaaCase) {
			setCasePerson(eaaCase.casePerson)
		}
	}, [eaaCase])

	const patchCasePerson = async () => {
		await patchData('case-persons', casePersonDialog).then(async (casePerson) => {
			eaaCase.casePersonId = casePerson.id
			await patchEaaCase(eaaCase)
			setDisplayCasePersonDialog(false)
		})
	}

	const renderHeader = () => {
		return <div className='flex flex-column'>
			<div className='flex font-bold mb-2'>Fallperson</div>
			<div className='flex justify-content-between border-bottom-1'>
				<div className='flex font-medium  mb-2 '>Person</div>
				<div className={currentUser.permissions === 'READER' || eaaCase && eaaCase.status && eaaCase.status.alias === 'INACTIVE' ? 'hidden' : 'flex gap-2'}>
					<div className='flex w-1rem' onClick={() => setDisplayCasePersonDialog(true)}><i className={eaaCase.casePerson && eaaCase.casePerson.id ? ENUM_ICONS.PENCIL : ENUM_ICONS.PLUS_CIRCLE} /></div>
					<div className={!casePerson ? 'hidden' : 'flex w-1rem'} onClick={() => setDisplayConfirmDialog(true)}><i className={ENUM_ICONS.TRASH} /></div>
				</div>

			</div>
		</div>
	}

	return (
		<>
			<ConfirmDialog title='Person entfernen' message={`Sind Sie sicher,\nPerson ${casePerson ? casePerson.personId : ''}\nvon dem Fall zu entfernen?'`} labelOk='Ja'
				handleOnClick={async () => { eaaCase.casePersonId = null; await patchEaaCase(eaaCase) }} displayConfirmDialog={displayConfirmDialog} setDisplayConfirmDialog={setDisplayConfirmDialog} />

			<Dialog header='Details zur Fallperson ändern' visible={displayCasePersonDialog} onHide={() => setDisplayCasePersonDialog(false)} id="dialog-caseperson" className='eaa-inputdialog'>
				<div className='flex font-light mb-3 p-0 '>{casePerson && casePerson.personId ? 'Hier können Sie die Details der Person ' + casePerson.personId + ' ändern.' : 'Hier können Sie eine neue Person zum Fall auswählen oder erstellen'}</div>
				<CasePersonsProvider><CasePersonForm eaaCase={eaaCase} handleCasePersonUpdate={setCasePersonDialog} setShowDialog={() => { }} /></CasePersonsProvider>
				<div className="flex flex-row card-container justify-content-end gap-2 mx-4 my-2">
					<Button className="button-cancel" label="Abbrechen" onClick={() => { setDisplayCasePersonDialog(false) }} />
					<Button onClick={patchCasePerson} disabled={!casePersonDialog} className="flex-order-0 ml-3 " label={eaaCase.casePerson && eaaCase.casePerson.id ? 'Ändern' : 'Neu hinzufügen'} />
				</div>

			</Dialog>
			<Card className="casepersonsheet card-sheet" header={renderHeader}>
				{!casePerson ? 'keine Person zugeordnet' :
					<>
						<div className="grid row">
							<div className="col-fixed">Person ID</div>
							<div className="col">{casePerson.personId}</div>
						</div>
						<div className="grid row">
							<div className="col-fixed">Grad der Behinderung Merkzeichen</div>
							<div className="col">{casePerson.GdBMark}</div>
						</div>
						<div className="grid row">
							<div className="col-fixed">Grad der Behinderung</div>
							<div className="col">{casePerson.GdB}</div>
						</div>
						<div className="grid row">
							<div className="col-fixed">Art der Behinderung</div>
							<div className="col">{casePerson.disablityKind}</div>
						</div>
						<div className="grid row">
							<div className="col-fixed">Geschlecht</div>
							<div className="col">{casePerson.gender.display}</div>
						</div>
					</>
				}
			</Card>
		</>
	)

}