import { InputNumber } from "primereact/inputnumber"
import { classNames } from "primereact/utils"

export const InputNumberChecked = ({ id, min, max, label, formik, className, setShowDialog, disabled, digits, showButtons, step }) => {

	const isFormFieldValid = (name) => { return formik.errors[name] };
	const getFormErrorMessage = (name) => {
		return isFormFieldValid(name) && <small className="p-error block" dangerouslySetInnerHTML={{ __html: formik.errors[id] }} />;
	};

	const onChange = (e) => {
		if (setShowDialog) {
			setShowDialog(true);
		}
		formik.setFieldValue(id, e.value)
	}

	return (
		<div className="field col">
			<label htmlFor={id} className='block'>{label}</label>
			<InputNumber key={'input-' + id} inputId={'input-' + id} max={max ? max : undefined} min={min ? min : 0} step={step ? step : 1} showButtons={showButtons} maxFractionDigits={digits ? digits : 0} minFractionDigits={digits ? digits : 0} value={formik.values[id]} onValueChange={onChange} disabled={disabled} mode="decimal" locale="de-DE" className={classNames({ 'p-invalid': isFormFieldValid(id) }) + ' ' + className} inputClassName={' ' + className} autoComplete="off" onFocus={(event) => { event.target.setAttribute('autocomplete', 'new-password') }} />
			{getFormErrorMessage(id)}
		</div>
	)

}

