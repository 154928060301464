import React, { useContext, useState, useEffect } from "react";
import { getData, updateListEntryInProvider, updateServiceListener } from '../feathers'
import { logContext, LoggerContext } from "../Utils/logger";

const listenernames = ['created', 'patched'];
const providername = 'RegionsProvider';
const servicename = 'regions';
const RegionsContext = React.createContext();
const RegionsSelectionContext = React.createContext();

export function useRegionsSelectionList() {
	return useContext(RegionsSelectionContext);
}

export function useRegionsList() {
	return useContext(RegionsContext);
}

export function RegionsProvider({ children }) {
	const [list, setList] = useState([]);
	const [listSelection, setListSelection] = useState([]);
	const [listItemChanged, setListItemChanged] = useState();
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		setMounted(true)
	}, [])

	useEffect(() => {
		if (listItemChanged) {
			logContext(providername, 'info', '########### ' + providername + ' listItemChanged', listItemChanged);
			updateListEntryInProvider(providername, listItemChanged, list, setList, servicename, false)
			setListItemChanged(null);
		}
	}, [listItemChanged, list])

	useEffect(() => {
		if (mounted) {
			logContext(providername, 'info', '########### ' + providername + ' update');
			updateServiceListener(servicename, listenernames, (_listentry) => setListItemChanged(_listentry));
			setListSelection(list.filter((entry) => entry.active))
		}

	}, [list])

	useEffect(() => {
		if (mounted) {
			getData(servicename).then((_list) => {
				setList(_list);
				const listSelection = _list.filter((entry) => entry.active)
				setListSelection(listSelection);
				logContext(providername, 'info', `########### ${providername} useEffect found:${_list.length} listSelection:${listSelection.length}`)
			}).catch((error) => { LoggerContext.error("Error " + providername, error) });
			return () => { LoggerContext.info(`########### ${providername} ---- unload`); updateServiceListener(servicename, listenernames, null); }
		}
	}, [mounted])

	return (

		<RegionsContext.Provider value={list}>
			<RegionsSelectionContext.Provider value={listSelection}>
				{children}
			</RegionsSelectionContext.Provider>
		</RegionsContext.Provider>
	)
}