import { Dialog } from "primereact/dialog"
import { getData, patchData } from "../feathers";
import logger, { logDefault } from "../Utils/logger";
import { AddressesForm } from "./AddressesForm"


const CLASSNAME = 'AddressDialog';
export const AddressDialog = ({ addressId, displayDialog, setDisplayDialog, handleSave }) => {

	const hideDialog = () => {
		setDisplayDialog(false)
	}

	return (<>
		<Dialog header={addressId ? 'Adresse ändern' : 'Neue Adresse hinzufügen'} visible={displayDialog} onHide={() => setDisplayDialog(false)} id="dialog-address" className='eaa-inputdialog' >
			<AddressesForm addressId={addressId} handleSave={handleSave} handleCancel={hideDialog} />
		</Dialog>
	</>)
}

export const handleSaveCompanyAddress = async (company, changedAddressId) => {
	/* Wenn company bereits gespeichert wurde -> NUR Assoziation speichern*/
	if (company.id && changedAddressId) {
		logDefault(CLASSNAME, 'info', 'handleSaveCompanyAddress changedAddressId:' + changedAddressId)
		await getData('addresses', changedAddressId).then(async (address) => {
			const companies = address.companies ? address.companies : []
			companies.push(company)
			address.companies = companies
			await patchData('addresses', address);
		}).catch((error) => {
			logger.error('Error while handleSave in ComapnyAddresses: ' + error.message)
		})
	}
}