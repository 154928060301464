import { TabPanel, TabView } from 'primereact/tabview';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ConfirmDialog } from '../Components/ConfirmDialog';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { useCurrentUserIsAdmin, useUserSwitchMode } from '../Persistence/CurrentUserContext';
import { patchData } from '../feathers';
import { RegionDataview } from './RegionDataview';
import { TaskareaDataview } from './TaskareaDataview';
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { OrganizationDataview } from './OrganizationDataview';

const CLASSNAME = 'Settings'
export const Settings = () => {
	const isAdmin = useCurrentUserIsAdmin();
	const userSwitchMode = useUserSwitchMode();
	const configurationsList = useConfigurationsList();
	const [toggleEntry, setToggleEntry] = useState(false);
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		if (!isAdmin && !userSwitchMode) {
			navigate('/' + ENUM_ROUTES.DASHBOARD)
		}
	})

	const toggleStatus = async () => {
		toggleEntry.active = (toggleEntry.active ? 0 : 1);
		await patchData(toggleEntry.servicename, toggleEntry);
		setDisplayConfirmDialog(false);
	}

	const handleStatusChange = async (entry, servicename, objectName) => {
		const command = (entry.active ? 'deaktivieren' : 'reaktivieren');
		const confirmTitle = objectName + ' ' + command
		const confirmMessage = `<span>Sind Sie sicher,<br/><b>${objectName + ': ' + entry.name}</b><br>zu ${command}?`
		setToggleEntry({ ...entry, servicename, confirmTitle, confirmMessage });
		setDisplayConfirmDialog(true);
	}

	return (
		<div className={!isAdmin && !userSwitchMode ? 'hidden' : ''}>
			<div className='mx-auto eaa-dataView flex gap-4'>
				<TabView className="w-full gap-2">
					<TabPanel header="Regionen" >
						<RegionDataview handleStatusChange={handleStatusChange} />
					</TabPanel>
					<TabPanel header="Organisationen">
						<OrganizationDataview handleStatusChange={handleStatusChange} />
					</TabPanel>
					<TabPanel header="Bereiche" className={configurationsList.eaaModules && !configurationsList.eaaModules.REQUESTDISTRIBUTION ? 'hidden' : ''}>
						<TaskareaDataview handleStatusChange={handleStatusChange} />
					</TabPanel>
				</TabView>
			</div>
			<ConfirmDialog title={toggleEntry.confirmTitle} message={toggleEntry.confirmMessage} labelOk='Ja'
				handleOnClick={toggleStatus} displayConfirmDialog={displayConfirmDialog} setDisplayConfirmDialog={setDisplayConfirmDialog} />
		</div>
	)

}