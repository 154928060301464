import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TemplateDataView } from '../Components/TemplateDataView';
import { patchData } from '../feathers';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { useBreadcrumbsAdd } from '../Persistence/Breadcrumbs';
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { useCurrentUser } from '../Persistence/CurrentUserContext';
import { ENUM_DATAVIEWS } from '../Persistence/DataViewFilterContext';
import { useJobOpeningsList } from '../Persistence/JobOpeningsContext';
import { ReminderDialog } from '../Reminders/ReminderDialog';
import { JobOpeningForm } from './JobOpeningForm';

const ENUM_DATAVIEWS_VALUE = ENUM_DATAVIEWS.JOBOPENINGS

export const JobOpeningDataview = () => {
	const addBreadcrumb = useBreadcrumbsAdd();
	const currentUser = useCurrentUser();
	const jobOpeningsList = useJobOpeningsList();
	const configurationsList = useConfigurationsList();

	const [jobOpening, setJobOpening] = useState();
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [displayCompanyDialog, setDisplayCompanyDialog] = useState(false);
	const [formHasErrors, setFormHasErrors] = useState();


	const navigate = useNavigate();
	useEffect(() => {
		if (configurationsList.eaaModules && configurationsList.eaaModules.JOBOPENING !== '1') {
			navigate('/' + ENUM_ROUTES.DASHBOARD)
		}
	}, [])

	const patchJobOpening = async () => {
		await patchData('job-openings', jobOpening, currentUser).then((jobOpening) => {
			const label = jobOpening.company.name + ' | ' + jobOpening.description;
			addBreadcrumb(`${label.length > 50 ? label.substring(0, 49) : label}`, `${ENUM_ROUTES.JOBPROFILES}/` + jobOpening.id);
		})
	}

	const columns = [
		{ field: "displayId", sortable: true, filter: "displayId", header: "ID", className: 'w-8rem', body: null },
		{ field: "company.name", sortable: true, filter: "company.name", header: "Unternehmen", className: '', body: (rowData) => { return rowData.company ? rowData.company.name : '' } },
		{ field: "location", sortable: true, filter: "location", header: "Adresse", className: 'w-15rem' },
		{ field: "description", sortable: true, filter: "description", header: "Stellenbeschreibung", className: '' },
		{ field: "jobType", sortable: true, filter: "jobType", header: "Typ", className: 'w-11rem', body: 'renderList' },
		{ field: "millisecondsOpeningDate", sortable: true, filter: "millisecondsOpeningDate", header: "Startdatum", className: 'w-5rem', body: 'renderDate' },
		{ field: "status", sortable: true, filter: "status", header: "Status", className: 'w-6rem', body: 'renderTag' }
	]
	return (
		<div className='mx-auto eaa-dataView'>
			<Dialog header='Neues Stellenprofil anlegen' visible={displayCompanyDialog} onHide={() => setDisplayCompanyDialog(false)} id="dialog-jobopening-details" className='eaa-inputdialog'>
				<JobOpeningForm editmode={true} setFormHasErrors={setFormHasErrors} setJobOpening={setJobOpening} key={'JobOpeningForm'} setShowDialog={() => { }} />
				<div className="flex flex-row card-container blue-container flex-row-reverse mx-4 my-2">
					<Button type="button" className="flex-order-1 button-cancel" label='Abbrechen' onClick={() => setDisplayCompanyDialog(false)} />
					<Button onClick={patchJobOpening} disabled={formHasErrors} className="flex-order-0 ml-3 " label='Neu anlegen' />
				</div>
			</Dialog>
			{!jobOpening ? '' : <ReminderDialog displayDialog={displayReminderDialog} setDisplayDialog={setDisplayReminderDialog} header='Unternehmen' name={jobOpening.name} serviceId={jobOpening.id} serviceName='job-openings' userId={currentUser.id} />}
			<div className="flex justify-content-end flex-wrap card-container ">
				<div className="flex align-self-center justify-content-left  my-2">
					<Button onClick={() => { setDisplayCompanyDialog(true) }} disabled={currentUser.permissions === 'READER'} className='flex  text-white' label='Neues Stellenprofil anlegen' />
				</div>
			</div>
			<TemplateDataView columns={columns} showOpenLinkColumn={true} dataTableList={jobOpeningsList} ENUM_DATAVIEWS_VALUE={ENUM_DATAVIEWS_VALUE} ROUTE={ENUM_ROUTES.JOBPROFILES} />

		</div>
	)

}