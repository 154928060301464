import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { CompanyAddresses } from "../Companies/CompanyAddresses";
import { CompanyContactPersons } from "../Companies/CompanyContactPersons";
import { CompanyForm } from "../Companies/CompanyForm";
import { getData, patchData } from "../feathers";
import { useCurrentUser } from "../Persistence/CurrentUserContext";
import logger from "../Utils/logger";

export const EAACaseCreateCompanyData = ({ eaaCase, handleSave, setActiveIndex, setShowDialog }) => {

	const currentUser = useCurrentUser();
	const [company, setCompany] = useState();
	const [companyForm, setCompanyForm] = useState();
	const [addresses, setAddresses] = useState([]);
	const [contactPersons, setContactPersons] = useState([]);
	const [companyFormHasErrors, setCompanyFormHasErrors] = useState(true);
	const [editmode, setEditmode] = useState(false);

	const [errorMessage, setErrorMessage] = useState();

	useEffect(() => {
		setEditmode(false);
		if (eaaCase && eaaCase.company && eaaCase.company.id) {
			getData("companies", eaaCase.company.id).then((company) => {
				setCompany(company);
				setAddresses(company.addresses ? company.addresses : [])
				setContactPersons(company.contactPersons ? company.contactPersons : [])
			});
		} else if (eaaCase && company !== null) {
			setCompany(null);
			setEditmode(true);
		} else {
			setEditmode(true)
		}

	}, [eaaCase])

	const onUpdateCompany = async () => {
		await getData('companies', company.id).then((company) => {
			setCompany(company)
			handleSave(company)
			setEditmode(false)
			setAddresses(company.addresses ? company.addresses : [])
			setContactPersons(company.contactPersons ? company.contactPersons : [])
		}).catch((error) => {
			logger.error('(CompaniesData->handleSelectExistingCompany) Fehler beim Holen eines Unternehmens: ' + error.message)
		})
	}

	const handleSelectExistingCompany = async (companyId) => {
		if (companyId === null) {
			setCompany(null)
			handleSave(null)
			setEditmode(true)
		} else {
			await getData('companies', companyId).then((company) => {
				setCompany(company)
				handleSave(company)
				setEditmode(false)
				setAddresses(company.addresses ? company.addresses : [])
				setContactPersons(company.contactPersons ? company.contactPersons : [])
			}).catch((error) => {
				logger.error('(CompaniesData->handleSelectExistingCompany) Fehler beim Holen eines Unternehmens: ' + error.message)
			})
		}
	}

	const patchCompany = async () => {
		if (companyForm.region) {
			if (!companyForm.regionId || companyForm.regionId === '') {
				companyForm.regionId = companyForm.region.id
			}
		}
		await patchData('companies', companyForm, currentUser).then((patchedCompany) => {
			setCompany(patchedCompany);
			handleSave(patchedCompany);
		}).catch((error) => {
			logger.error('Fehler beim Speichern des Unternehmens: ' + error.message)
			setErrorMessage('Fehler beim Speichern des Unternehmens: ' + error.message)
		});
	}

	return (
		<div className='col'>
			<CompanyForm company={company} editmode={editmode} setFormHasErrors={setCompanyFormHasErrors} setCompanyForm={setCompanyForm} handleSelectExistingCompany={handleSelectExistingCompany} setCompany={setCompany} key={'CompaniesForm'} setShowDialog={setShowDialog} createEAA={true} />
			<div className={editmode ? 'card pt-2' : 'hidden'}>
				<div className="flex flex-row card-container blue-container flex-row-reverse m-4">
					<Button onClick={() => { patchCompany(); setActiveIndex([0, 1]) }} disabled={companyFormHasErrors} className="flex-order-0 ml-3 " label={'Speichern und weiter'} />
				</div>
			</div>
			<div className={company && company.id ? 'flex flex-column mr-4' : 'hidden'}>
				<CompanyAddresses company={company} addresses={addresses ? addresses : []} numCarousel={2} onUpdateCompany={onUpdateCompany} />
				<CompanyContactPersons company={company} contactPersons={contactPersons ? contactPersons : []} numCarousel={2} onUpdateCompany={onUpdateCompany} />
			</div>
			<div className={!editmode ? 'card pt-4 mr-4' : 'hidden'}>
				<div className="flex flex-row card-container blue-container flex-row-reverse">
					<Button type="button" className="flex-order-1 " label='Weiter' onClick={() => { setActiveIndex([1]) }} />
				</div>
			</div>
		</div>


	)
}
