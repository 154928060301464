import React, { useContext, useState } from "react";
import { getData } from "../feathers";
import { LoggerContext } from "../Utils/logger";
import { SocketErrorProvider } from "./SocketErrorContext";

const CurrentUserContext = React.createContext();
const CurrentUserUpdateContext = React.createContext();
const CurrentUserIsSuperAdminContext = React.createContext();
const CurrentUserIsAdminContext = React.createContext();
const CurrentUserIsReaderContext = React.createContext();
const UserSwitchModeContext = React.createContext();
const UserSwitchModeUpdateContext = React.createContext();

export function useCurrentUser() {
	return useContext(CurrentUserContext);
}
export function useCurrentUserUpdate() {
	return useContext(CurrentUserUpdateContext);
}
export function useCurrentUserIsSuperAdmin() {
	return useContext(CurrentUserIsSuperAdminContext);
}
export function useCurrentUserIsAdmin() {
	return useContext(CurrentUserIsAdminContext);
}
export function useCurrentUserIsReader() {
	return useContext(CurrentUserIsReaderContext);
}
export function useUserSwitchMode() {
	return useContext(UserSwitchModeContext);
}
export function useUserSwitchModeUpdate() {
	return useContext(UserSwitchModeUpdateContext);
}

export function CurrentUserProvider({ children }) {
	const [currentUser, setCurrentUser] = useState(null);
	const [isSuperAdmin, setIsSuperAdmin] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const [isReader, setIsReader] = useState(false);
	const [switchedUser, setSwitchedUser] = useState(null);
	const [isSwitchMode, setIsSwitchMode] = useState(false);

	function setUser(user) {
		if (user) {
			const { id, isAdmin, displayname, roles, permissions, forceChangePassword } = user;
			setIsSuperAdmin(user.isAdmin === 1);
			setIsAdmin(user.permissions === 'ADMIN');
			setIsReader(user.permissions === 'READER');
		} else {
			setIsSuperAdmin(false);
			setIsAdmin(false);
			setIsReader(true);
			LoggerContext.verbose("############# setCurrentUser ###############");
		}
		setCurrentUser(user);
	}

	async function setSwitchMode(switchUser) {
		await getData('users', currentUser.id).then((user) => {
			if (!user.isAdmin || !switchUser) {
				setIsSwitchMode(false);
				setSwitchedUser(null)
				setIsAdmin(currentUser.permissions === 'ADMIN');
				setIsReader(currentUser.permissions === 'READER');
			} else {
				if (switchUser) {
					switchUser.permissions = 'READER';
					switchUser.isSwitchedUser = true;
				}
				setIsSwitchMode(true);
				setSwitchedUser(switchUser);
				setIsAdmin(switchUser.permissions === 'ADMIN');
				setIsReader(switchUser.permissions === 'READER');
			}

		})
	}

	return (
		<CurrentUserContext.Provider value={switchedUser || currentUser}>
			<CurrentUserIsSuperAdminContext.Provider value={isSuperAdmin || isSwitchMode}>
				<CurrentUserIsAdminContext.Provider value={isAdmin}>
					<CurrentUserIsReaderContext.Provider value={isReader}>
						<CurrentUserUpdateContext.Provider value={setUser}>
							<UserSwitchModeContext.Provider value={isSwitchMode}>
								<UserSwitchModeUpdateContext.Provider value={setSwitchMode}>
									<SocketErrorProvider>
										{children}
									</SocketErrorProvider>
								</UserSwitchModeUpdateContext.Provider>
							</UserSwitchModeContext.Provider>
						</CurrentUserUpdateContext.Provider>
					</CurrentUserIsReaderContext.Provider>
				</CurrentUserIsAdminContext.Provider>
			</CurrentUserIsSuperAdminContext.Provider>
		</CurrentUserContext.Provider>
	)
}