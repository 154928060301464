import { SplitButton } from "primereact/splitbutton";
import { Divider } from "primereact/divider";
import { useCurrentUser } from "../Persistence/CurrentUserContext";
import { useEffect, useState } from "react";

export const CaseEventDivider = ({ caseEvents, icon, label, onClickHandler, className }) => {
	const currentUser = useCurrentUser();

	const [disableItems, setDisableItems] = useState();

	useEffect(() => {
		const caseEvent = caseEvents && caseEvents.length > 0 ? caseEvents[0] : null;
		setDisableItems(!(caseEvent && caseEvent.id))

	}, [caseEvents])

	const items = [
		{ label: 'Neues Ergebnis', disabled: disableItems, command: () => { onClickHandler('CONTACTRESULT') } },
		{ label: 'Neue Nachhaltigkeit', disabled: disableItems, command: () => { onClickHandler('SUSTAINABILITY') } },
		{ label: 'Neue interne Notiz', disabled: disableItems, command: () => { onClickHandler('INTERN') } },
	]

	return (
		<Divider className={"caseevent-divider " + (className ? className : ' h-1rem')} align="center">
			<SplitButton className='m-0 p-0' label={label} icon={icon + " mr-3"} onClick={() => onClickHandler('CONTACT')} model={items} disabled={currentUser.permissions === 'READER'} ></SplitButton>
		</Divider>

	)
}