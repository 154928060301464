
import { format, startOfYear, startOfDay, endOfDay } from 'date-fns';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { useState } from "react"
import client from '../feathers';
import { useCurrentUser, useCurrentUserIsSuperAdmin } from '../Persistence/CurrentUserContext';
import { StatisticOrg } from './StatisticOrg';
import { StatisticPerson } from './StatisticPerson';
import { UserProvider } from "../Persistence/UserContext"

export const Statistic = () => {

	const currentUser = useCurrentUser();
	const isAdmin = useCurrentUserIsSuperAdmin();
	const [dateFrom, setDateFrom] = useState(startOfYear(new Date()));
	const [dateTo, setDateTo] = useState(new Date());

	const onClickCreateStatistic = async (startDate, endDate, userId, organizationId) => {
		//alert('Statistic generieren für ' + currentUser.displayname + ' von ' + format(dateFrom, 'yyyy-MM-dd') + ' bis ' + format(dateTo, 'yyyy-MM-dd'))
		await client.service('utils').create({ currentUser, method: 'statistic', timezoneOffset: startDate.getTimezoneOffset(), startDate: startOfDay(startDate), endDate: endOfDay(endDate), userId, organizationId, printStopwatch: isAdmin }).then(async (result) => {
			const fileName = format(new Date(), 'yyyy-MM-dd_HH-mm-ss') + '_BIH_Statistik.pdf';
			const base64 = await fetch(result);
			var blob = await base64.blob();

			var file = new File([blob], fileName, { lastModified: new Date().getTime(), type: "application/pdf" });
			let exportUrl = URL.createObjectURL(file);
			var fileLink = document.createElement('a');
			fileLink.href = exportUrl;
			fileLink.download = fileName;
			fileLink.click();
		})
	}

	return (
		<div className='mx-auto eaa-dataView'>
			<UserProvider> <StatisticPerson onClickCreateStatistic={onClickCreateStatistic} /></UserProvider>
			<div className="card shadow-2 p-4 my-4 bg-white">
				<div className='font-bold mb-4'>Allgemeine Statistik</div>
				<div className="p-fluid grid formgrid gap-4">
					<div className="field col-fixed w-15rem">
						<label htmlFor="datePersFrom">Von</label>
						<Calendar maxDate={dateTo} id="datePersFrom" value={dateFrom} onChange={(e) => setDateFrom(e.value)} showIcon dateFormat="dd.mm.yy" />
					</div>
					<div className="field col-fixed w-15rem">
						<label htmlFor="datePersTo">Bis</label>
						<Calendar minDate={dateFrom} maxDate={new Date()} id="datePersTo" value={dateTo} onChange={(e) => setDateTo(e.value)} showIcon dateFormat="dd.mm.yy" />
					</div>
				</div>
				<div className='flex '>
					<Button disabled={dateFrom == null || dateTo == null} onClick={() => onClickCreateStatistic(dateFrom, dateTo)} className='flex  text-white' label='Statistik generieren' />
				</div>
			</div>

			<StatisticOrg onClickCreateStatistic={onClickCreateStatistic} />
		</div>
	)

}