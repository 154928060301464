import React, { useContext, useState, useEffect } from "react";
import { getData, updateListEntryInProvider, updateServiceListener } from '../feathers'
import { logContext, LoggerContext } from "../Utils/logger";

const CLASSNAME = 'EAACasesProvider';
const listenernames = ['created', 'patched', 'removed'];
const providername = 'EAACasesProvider';
const servicename = 'eaa-cases';
const EAACasesContext = React.createContext();

export function useEAACasesList() {
	return useContext(EAACasesContext);
}

export function EAACasesProvider({ children }) {
	const [list, setList] = useState([]);
	const [listItemChanged, setListItemChanged] = useState();
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		setMounted(true)
	}, [])

	useEffect(() => {
		if (listItemChanged) {
			logContext(CLASSNAME, 'info', 'ListItemChanged', listItemChanged)
			if (typeof listItemChanged.status === 'object') {
				listItemChanged.status = listItemChanged.status.alias
			}
			updateListEntryInProvider(providername, listItemChanged, list, setList, servicename, false)
			setListItemChanged(null);
		}
	}, [listItemChanged, list])

	useEffect(() => {
		LoggerContext.debug('########### ' + providername + ' useEffect update list entries: ' + list.length);
		updateServiceListener(servicename, listenernames, (_listentry) => setListItemChanged(_listentry));
	}, [list])

	useEffect(() => {
		if (mounted) {
			LoggerContext.debug('########### ' + providername + ' getData');
			getData(servicename).then((_list) => {
				setList(_list);
				LoggerContext.debug(`########### ${providername} useEffect found:${_list.length}`);
			}).catch((error) => { LoggerContext.error("Error " + providername, error) });
			return () => { LoggerContext.debug(`########### ${providername} ---- unload`); updateServiceListener(servicename, listenernames, null); }
		}
	}, [mounted])

	return (
		<EAACasesContext.Provider value={list}>
			{children}
		</EAACasesContext.Provider>
	)
}