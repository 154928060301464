import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { TemplateDataView } from "../Components/TemplateDataView";
import { ENUM_ROUTES } from "../Navigation/Routes";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { ENUM_DATAVIEWS } from "../Persistence/DataViewFilterContext";
import { useRequestDistributionsList } from "../Persistence/RequestDistributionsContext";
import { Tag } from 'primereact/tag';

const ENUM_DATAVIEWS_VALUE = ENUM_DATAVIEWS.REQUESTDISTRIBUTIONS

export const RequestDistributionsDataview = () => {
	const dataTableList = useRequestDistributionsList();
	const configurationsList = useConfigurationsList();
	const [mounted, setMounted] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (configurationsList.TaskareasList && configurationsList.TaskareasList.length > 0 && configurationsList.RegionsList && configurationsList.RegionsList.length > 0) {
			setMounted(true)
		}
	}, [dataTableList, configurationsList])

	const columns = [
		{ field: "displayId", sortable: true, filter: "displayId", header: "ID", className: 'w-8rem', body: null },
		{ field: "subject", sortable: true, filter: true, header: "Betreff", className: 'w-15rem' },
		{ field: "email", sortable: true, filter: true, header: "Absender", className: '' },
		{ field: "millisecondsDate", sortable: true, filter: "millisecondsDate", header: "Anfragedatum", className: 'w-14rem', body: 'renderDate' },
		{ field: "regions", sortable: true, filter: 'regions', header: "Regionen", className: '', body: (row) => row.regions.map(e => <Tag className={"mb-2 mr-2 px-3"} rounded value={e.name} />) },
		{ field: "taskareas", sortable: true, filter: "taskareas", header: "Bereiche", className: '', body: (row) => row.taskareas.map(e => <Tag className={"mb-2 mr-2 px-3"} style={{ color: '#' + e.color, backgroundColor: '#' + e.backcolor }} rounded value={e.name} />) }
	]

	return (!mounted ? <></> :
		<div className='mx-auto eaa-dataView'>
			<div className="flex justify-content-end flex-wrap card-container ">
				<div className="flex align-self-center justify-content-left my-2 gap-2">
					<Button onClick={() => { navigate('/' + ENUM_ROUTES.REQUESTDISTRIBUTIONARCHIVE) }} className='flex text-white' label='Archiv' />
					<Button onClick={() => { navigate('/' + ENUM_ROUTES.REQUESTDISTRIBUTIONUSERRELATIONS) }} className='flex text-white' label='Zuordnungen Berater*innen' />
				</div>
			</div>
			<TemplateDataView columns={columns} showOpenLinkColumn={true} dataTableList={dataTableList} ENUM_DATAVIEWS_VALUE={ENUM_DATAVIEWS_VALUE} ROUTE={ENUM_ROUTES.REQUESTDISTRIBUTION} />
		</div>
	)
}