import React, { useContext, useState, useEffect } from "react";
import { getData, updateListEntryInProvider, updateServiceListener } from '../feathers'
import { logContext, LoggerContext } from "../Utils/logger";

const listenernames = ['created', 'patched', 'removed'];
const providername = 'CompaniesProvider';
const servicename = 'companies';
const CompaniesContext = React.createContext();

export function useCompaniesList() {
	return useContext(CompaniesContext);
}

export function CompaniesProvider({ children }) {
	const [list, setList] = useState([]);
	const [listItemChanged, setListItemChanged] = useState();
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		LoggerContext.debug('########### ' + providername + ' mount');
		setMounted(true)
	}, [])

	useEffect(() => {
		if (listItemChanged) {
			logContext(providername, 'info', 'ListItemChanged', listItemChanged)
			updateListEntryInProvider(providername, listItemChanged, list, setList, servicename, false)
			setListItemChanged(null);
		}
	}, [listItemChanged, list])

	useEffect(() => {
		logContext(providername, 'info', '########### ' + providername + ' update');
		updateServiceListener(servicename, listenernames, (_listentry) => setListItemChanged(_listentry));
	}, [list])

	useEffect(() => {
		if (mounted) {
			getData(servicename).then((_list) => {
				setList(_list);
				logContext(providername, 'info', `########### ${providername} useEffect found:${_list.length}`)
			}).catch((error) => { LoggerContext.error("Error " + providername, error) });
			return () => { LoggerContext.debug(`########### ${providername} ---- unload`); updateServiceListener(servicename, listenernames, null); }
		}
	}, [mounted])

	return (
		<CompaniesContext.Provider value={list}>
			{children}
		</CompaniesContext.Provider>
	)
}