import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { CompanyForm, emptyCompany } from "./CompanyForm"
import { useEffect, useState } from "react";
import { getData, patchData } from "../feathers";
import { useCurrentUser } from "../Persistence/CurrentUserContext";
import { useCallbackPrompt } from "../Utils/useCallbackPrompt";
import logger from "../Utils/logger";

export const CompanyChangeDialog = ({ companyId, displayCompanyDialog, setDisplayCompanyDialog, handleOnCompanyPatched }) => {

	const currentUser = useCurrentUser();

	const [companyFormHasErrors, setCompanyFormHasErrors] = useState(true);
	const [company, setCompany] = useState(emptyCompany);
	const [showDialog, setShowDialog] = useState(false);
	const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);
	const [errorMessage, setErrorMessage] = useState();

	useEffect(() => {
		if (companyId) {
			getData("companies", companyId).then((_company) => {
				setCompany(_company);
			});
		}
	}, [companyId])

	const setCompanyFromDialog = (company) => {
		setCompany(company);
	}

	const closeDialogChangeCompany = async () => {
		await getData("companies", companyId).then((_company) => {
			setCompany(_company);
			setDisplayCompanyDialog(false);
			setShowDialog(false);
		});
	}

	const patchCompany = async () => {
		if (company.region) {
			if (!company.regionId || company.regionId === '') {
				company.regionId = company.region.id
			}
			company.regionId = company.region.id
		}
		await patchData('companies', company, currentUser).then((patchedCompany) => {
			setCompany(patchedCompany);
			handleOnCompanyPatched(patchedCompany);
			setDisplayCompanyDialog(false);
			setShowDialog(false);
		}).catch((error) => {
			logger.error('Fehler beim Speichern des Unternehmens: ' + error.message)
			setErrorMessage('Fehler beim Speichern des Unternehmens: ' + error.message)
		});
	}

	return (
		<Dialog header='Unternehmensdetails ändern' visible={displayCompanyDialog} onHide={closeDialogChangeCompany} id="dialog-company-details" className='eaa-inputdialog'>
			<div className='flex font-light mb-3 p-0 '>{'Hier können Sie die Details des Unternehmens ' + company.name + ' ändern.'}</div>
			<CompanyForm company={company} editmode={true} setFormHasErrors={setCompanyFormHasErrors} setCompanyForm={setCompanyFromDialog} key={'CompaniesForm'} setShowDialog={setShowDialog} />
			<div className="flex flex-row card-container blue-container flex-row-reverse mx-4 my-2">
				<Button type="button" className="flex-order-1 button-cancel" label='Abbrechen' onClick={closeDialogChangeCompany} />
				<Button onClick={patchCompany} disabled={companyFormHasErrors} className="flex-order-0 ml-3 " label={company && company.id ? 'Speichern' : 'Neu anlegen'} />
			</div>
		</Dialog>
	)
}