import { useEffect, useState } from 'react';
import { TemplateDataView } from "../Components/TemplateDataView";
import { ENUM_ROUTES } from "../Navigation/Routes";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { ENUM_DATAVIEWS } from "../Persistence/DataViewFilterContext";
import { useRequestDistributionsAssignedList } from '../Persistence/RequestDistributionsAssignedContext';
import { logDefault } from '../Utils/logger';
import { Tag } from 'primereact/tag';

const ENUM_DATAVIEWS_VALUE = ENUM_DATAVIEWS.REQUESTDISTRIBUTIONSARCHIV
const CLASSNAME = 'RequestDistributionsArchivDataview';
export const RequestDistributionsArchivDataview = () => {

	const dataTableList = useRequestDistributionsAssignedList();
	const configurationsList = useConfigurationsList();
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		if (dataTableList && configurationsList.TaskareasList && configurationsList.TaskareasList.length > 0 && configurationsList.RegionsList && configurationsList.RegionsList.length > 0) {
			logDefault(CLASSNAME, 'info', 'dataTableList: ' + dataTableList.length, dataTableList, configurationsList.length)
			setMounted(true)
		}
	}, [dataTableList, configurationsList])

	const columns = [
		{ field: "displayId", sortable: true, filter: "displayId", header: "ID", className: 'w-8rem', body: null },
		{ field: "subject", sortable: true, filter: true, header: "Betreff", className: 'w-15rem' },
		{ field: "email", sortable: true, filter: true, header: "Absender", className: '' },
		{ field: "millisecondsDate", sortable: true, filter: "millisecondsDate", header: "Anfragedatum", className: 'w-10rem', body: 'renderDate' },
		{ field: "regions", sortable: true, filter: 'regions', header: "Regionen", className: '', body: (row) => row.regions.map(e => <Tag className={"mb-2 mr-2 px-3"} rounded value={e.name} />) },
		{ field: "taskareas", sortable: true, filter: "taskareas", header: "Bereiche", className: '', body: (row) => row.taskareas.map(e => <Tag className={"mb-2 mr-2 px-3"} style={{ color: '#' + e.color, backgroundColor: '#' + e.backcolor }} rounded value={e.name} />) },
		{ field: "assigned", sortable: true, filter: "assigned", header: "Zugewiesen an", className: '', body: (row) => row.assigned.lastname + ', ' + row.assigned.firstname }
	]

	return (!mounted ? <></> :
		<div className='mx-auto eaa-dataView'>
			<TemplateDataView columns={columns} dataTableList={dataTableList} ENUM_DATAVIEWS_VALUE={ENUM_DATAVIEWS_VALUE} ROUTE={ENUM_ROUTES.REQUESTDISTRIBUTIONARCHIVE} />
		</div>
	)
}