import { format, parseISO } from 'date-fns';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ConfirmDialog } from '../Components/ConfirmDialog';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { FieldInfo } from "../Components/FieldInfo";
import { ENUM_ICONS } from '../Components/Icons';
import { BasicSheetTitleLayout } from '../Components/Layouts/BasicSheetTitleLayout';
import { OverlayPanelMenu } from '../Components/OverlayPanelMenu';
import { deleteData, getData, patchData } from '../feathers';
import { useBreadcrumbsMinus } from '../Persistence/Breadcrumbs';
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { useCurrentUser } from '../Persistence/CurrentUserContext';
import { logDefault } from '../Utils/logger';
import { ToastError } from '../Utils/ToastError';
import { hasParticipants, ProviderActivitiesForm, defaultProviderActivity, validateProviderActivity } from './ProviderActivitiesForm';


const SERVICENAME = 'provideractivities';
const CLASSNAME = 'ProviderActivitySheet'
export const ProviderActivitySheet = () => {
	const currentUser = useCurrentUser();
	const configurationsList = useConfigurationsList();
	const { provideractivityId } = useParams();
	const minusBreadcrumbs = useBreadcrumbsMinus();
	const [providerActivity, setProviderActivity] = useState();
	const [displayProviderActivityDialog, setDisplayProviderActivityDialog] = useState(false);
	const [displayDeleteConfirmDialog, setDisplayDeleteConfirmDialog] = useState(false);
	const [readonly, setReadonly] = useState(true);
	const [mounted, setMounted] = useState(false);
	const [toastParams, setToastParams] = useState(null);


	useEffect(() => {
		if (mounted) {
			getProviderActivity(provideractivityId)
		}
	}, [provideractivityId, mounted])

	useEffect(() => {
		setReadonly(currentUser.permissions === 'READER')
		setTimeout(() => setMounted(true), 100);
	}, [])

	const getProviderActivity = async (provideractivityId) => {
		if (provideractivityId) {
			await getData(SERVICENAME, provideractivityId).then((providerActivity) => {
				providerActivity.date = parseISO(providerActivity.date)
				setProviderActivity(providerActivity);
				logDefault(CLASSNAME, 'info', 'getProviderActivity', providerActivity);
			}).catch((error) => {
				setToastParams({ title: 'Aktivität mit der Nummer "' + provideractivityId + '" konnte nicht ermittelt werden', message: error.message })
			});
		}
	}

	const getTypeActivity = (providerActivity) => {
		const typeActivity = configurationsList.ProvideractivitiesTypeList.find(entry => entry.alias === providerActivity.typeActivity);
		logDefault(CLASSNAME, 'info', 'getTypeActivity: ' + providerActivity.typeActivity, typeActivity);
		return typeActivity;
	}

	const patchProviderActivity = async () => {
		await patchData(SERVICENAME, formik.values).then(async (patchedProviderActivity) => {
			await getProviderActivity(patchedProviderActivity.id);
		})
	}

	const onEditActivity = () => {
		formik.setValues({ ...providerActivity, endDate: providerActivity.endDate ? parseISO(providerActivity.endDate) : null, recurring: providerActivity.recurring === 1 })
		setDisplayProviderActivityDialog(true)
	}

	const deleteActivity = async () => {
		setDisplayDeleteConfirmDialog(false);
		await deleteData(SERVICENAME, providerActivity.id).then(async () => {
			minusBreadcrumbs();
		}).catch((error) => {
			setToastParams({ title: 'Aktivität konnte nicht gelöscht werden', message: error.message })
		})
	}
	const renderItems = () => {
		const items = []
		if (currentUser.permissions === 'ADMIN' && providerActivity) {
			items.push({ label: 'Aktivität unwiderruflich löschen', className: 'eaa-menuitem-delete', icon: ENUM_ICONS.CASE_DELETE, command: () => setDisplayDeleteConfirmDialog(true) })
		}
		return items;
	}
	const formik = useFormik({
		initialValues: { ...defaultProviderActivity, userId: currentUser.id },
		validate: validateProviderActivity,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});
	const listButtons = [
		{ key: 'edit', label: 'Bearbeiten', icon: ENUM_ICONS.PENCIL, command: onEditActivity, disabled: currentUser.permissions === 'READER' },
	]

	return (<>
		<div className='mx-auto eaa-maincontent'>
			{!providerActivity ? '' : <div> <ToastError toastParams={toastParams} />
				<ConfirmDialog title='Fall löschen' message={`<span>Wollen Sie die Trägeraktivität<br/><b>${providerActivity.name}</b><br/>unwiederruflich löschen?<span>`} labelOk='Ja'
					handleOnClick={deleteActivity} displayConfirmDialog={displayDeleteConfirmDialog} setDisplayConfirmDialog={setDisplayDeleteConfirmDialog} />

				<EditDialog
					form={<ProviderActivitiesForm formik={formik} key='providerActivity-form' />}
					formik={formik}
					patchForm={patchProviderActivity}
					header={'Aktivität bearbeiten'}
					message={'Hier können sie die Aktivität bearbeiten'}
					setDisplayDialog={setDisplayProviderActivityDialog}
					displayRegionDialog={displayProviderActivityDialog}
				/>

				<BasicSheetTitleLayout
					listButtons={listButtons}
					title={providerActivity.name}
					subtitle={providerActivity.displayId + ' erstellt am ' + (providerActivity.createdAt ? format(parseISO(providerActivity.createdAt), 'yyyy-MM-dd') : '')}
					overlaypanel={<OverlayPanelMenu items={renderItems()} className='flex' />} />

				<div className='flex card-container blue-container overflow-hidden grid'>
					<FieldInfo label='Typ' content={getTypeActivity(providerActivity).display} className='col-5' />
					<FieldInfo label='Ersteller*in der Aktivität' content={!providerActivity.creator ? '' : providerActivity.creator.displayname} className='col-5' />
					{hasParticipants(providerActivity.typeActivity)
						? <FieldInfo label='Teilnehmerzahlen' content={providerActivity.participants} className='col-2' />
						: providerActivity.typeActivity === 'COOPERATION_MEETINGS' ? '' : <FieldInfo label='Wiederkehrend bis' content={providerActivity.endDate ? format(parseISO(providerActivity.endDate), 'yyyy-MM-dd') : '---'} className='col-2' />}
				</div>

				<div className='flex flex-column surface-ground p-2 mx-2'>
					<div className='flex text-600 text-base mb-1'>
						Beschreibung
					</div>
					<div className='editor-changes-checked mx-4' dangerouslySetInnerHTML={{ __html: providerActivity.description }} />
				</div>
			</div>}
		</div></>
	)

}