import { useTaskareaTagsList } from "../Persistence/TaskareaTagsContext";
import { useState, useEffect } from "react";
import { InputField } from "../Components/InputField";
import { SelectButton } from 'primereact/selectbutton';
import { logDefault } from "../Utils/logger";
import { Chips } from 'primereact/chips';
import { ToastError } from "../Utils/ToastError";
import { Colorpicker } from "../Components/FormElements/Colorpicker";
import { Tag } from "primereact/tag";

const CLASSNAME = 'TaskareaForm';

export const TaskareaForm = ({ formik }) => {
	const taskareaTagsList = useTaskareaTagsList();
	const [toastParams, setToastParams] = useState(null);

	useEffect(() => {
		logDefault(CLASSNAME, 'info', 'formik', formik.values)
	}, [formik.values])

	const addTag = (item) => {
		/** Wenn das Tag bereits existiert und einer anderen Region zugewiesen ist -> message und Eintrag löschen */
		const existingTag = taskareaTagsList.find(e => (e.alias.toLowerCase() === item.value.toLowerCase() && e.taskareaId !== formik.values.id))
		if (existingTag && existingTag.taskareaId) {
			/** Toast */
			setToastParams({ title: 'Abkürzung kann nicht verwendet werden', message: 'Diese Abkürzung wird bereits im Aufgabenbereich ' + existingTag.taskarea.name + ' verwendet!' })
			return false;
		}
	}

	return (
		<div className='mr-3'>
			<ToastError toastParams={toastParams} />
			<div className="formgrid grid">
				<InputField formik={formik} id='name' label='Name' type='text' />
			</div>
			<div className="formgrid grid">
				{/* <Colorpicker formik={formik} id='color' label='Textfarbe' /> */}
				<div className='field col'>
					<label htmlFor='color' className='block'>Textfarbe</label>
					<SelectButton id='color' value={formik.values.color} options={[{ name: 'Schwarz', value: '000000' }, { name: 'Weiß', value: 'ffffff' }]} onChange={(e) => { formik.handleBlur(e); formik.handleChange(e); }} optionValue='value' optionLabel="name" />
				</div>
				<Colorpicker formik={formik} id='backcolor' label='Hintergrundfarbe' />
				<div className={"field col"}>
					<label htmlFor='preview' className='block'>Vorschau</label>
					<Tag className={"mr-2 px-3"} style={{ color: '#' + formik.values.color, backgroundColor: '#' + formik.values.backcolor }} rounded value={formik.values.name} />
				</div>
			</div>
			<div className={'formgrid grid'}>
				<div className='field  col  p-fluid'>
					<label htmlFor='tags' className='block'>Abkürzungen des Aufgabenbereichs</label>
					<Chips onAdd={addTag} allowDuplicate={false} name='tags' value={formik.values.tags} onChange={(e) => { formik.handleBlur(e); formik.handleChange(e); }} />
				</div>
			</div>
		</div>
	)
}
export const emptyTaskarea = { id: null, active: 1, version: 1, alias: '', color: 'ffffff', backcolor: '000000', tags: [] }
export const validateTaskarea = (data) => {
	let errors = {};
	if (!data.name) {
		errors.name = 'Name ist ein Pflichtfeld';
	}
	if (!data.color) {
		errors.color = 'Textfarbe ist ein Pflichtfeld';
	}
	if (!data.backcolor) {
		errors.backcolor = 'Hintergrundfarbe ist ein Pflichtfeld';
	}
	return errors;
}