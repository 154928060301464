
import { MultiSelect } from 'primereact/multiselect';
import { useEffect, useState } from 'react';
import { useConfigurationsList } from '../../Persistence/ConfigurationsContext';
import { logDefault } from '../../Utils/logger';

const CLASSNAME = 'MultiSelectFilterElement';
export const MultiSelectFilterElement = ({ options }) => {
	const configurationsList = useConfigurationsList();
	const [listOptions, setListOptions] = useState();
	const [selected, setSelected] = useState([]);
	const [placeholder, setPlaceholder] = useState();
	const [filterSelectedLabel, setFilterSelectedLabel] = useState();
	const [maxSelectedLabels, setMaxSelectedLabels] = useState();

	useEffect(() => {
		if (options.filterModel) {
			setListOptions(configurationsList[options.filterModel.filterListName]);
			setPlaceholder(options.filterModel.filterPlaceholder);
			setFilterSelectedLabel(options.filterModel.filterSelectedLabel);
			setMaxSelectedLabels(options.filterModel.maxSelectedLabels);
		}
	}, [options])


	const itemTemplate = (option) => {
		return option ? option.name : ''
	}

	const selectedTemplate = (option) => {
		return option ? <div className="flex text-xm ">{listOptions.find(e => e.id === option).name}</div> : '';
	}

	const panelFooterTemplate = () => {
		const selectedItems = options.value;
		const length = selectedItems ? selectedItems.length : 0;
		return (
			<div className="py-2 px-3">
				<b>{length}</b> {filterSelectedLabel} ausgewählt.
			</div>
		);
	}

	return (<>
		{(!listOptions || listOptions.length === 0) ? 'keine ' + filterSelectedLabel + ' gelistet' :
			<MultiSelect className='multiselect-custom' id={'multiSelectFilter_' + options.filterModel.filterListName} value={options.value} optionValue='id' optionLabel="name" options={listOptions} onChange={(e) => options.filterApplyCallback(e && e.value && e.value.length === 0 ? null : e.value)} itemTemplate={itemTemplate} selectedItemTemplate={selectedTemplate} panelFooterTemplate={panelFooterTemplate} placeholder={placeholder} selectedItemsLabel={(options.value ? options.value.length : 0) + ' ' + filterSelectedLabel + ' ausgewählt'} maxSelectedLabels={3} />
		}
	</>)
}