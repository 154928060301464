import { useEffect, useState } from 'react';
import { Button } from "primereact/button";
import { TemplateDataView } from "../Components/TemplateDataView";
import { OverlayPanelMenu } from "../Components/OverlayPanelMenu";
import { ENUM_ICONS } from "../Components/Icons";
import { getData, patchData } from "../feathers";
import { logDefault } from "../Utils/logger";
import { useFormik } from "formik";
import { RegionForm, emptyRegion, validateRegion } from "./RegionForm";
import { useRegionsList } from "../Persistence/RegionsContext";
import { useRegionTagsList } from "../Persistence/RegionTagsContext";
import { Tag } from 'primereact/tag';
import { useCurrentUserIsAdmin } from '../Persistence/CurrentUserContext';
import { ENUM_DATAVIEWS } from '../Persistence/DataViewFilterContext';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { usePostcodesList } from '../Persistence/PostcodesContext';
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';

const CLASSNAME = 'RegionDataview'
const ENUM_DATAVIEWS_VALUE = ENUM_DATAVIEWS.REGIONS
export const RegionDataview = ({ handleStatusChange }) => {
	const configurationsList = useConfigurationsList();
	const isAdmin = useCurrentUserIsAdmin();
	const regionsList = useRegionsList();
	const regionsTagList = useRegionTagsList();
	const postcodesList = usePostcodesList();
	const [displayRegionDialog, setDisplayRegionDialog] = useState(false);


	const patchRegion = async () => {
		const region = { ...formikRegion.values };
		region.postcodes = region.postcodes.map(e => { return postcodesList.find(p => p.postcode === e) || { postcode: e } })
		region.tags = region.tags.map(e => { return regionsTagList.find(p => p.alias.toLowerCase() === e.toLowerCase()) || { alias: e.toLowerCase() } })
		logDefault(CLASSNAME, 'info', 'patchRegion postcodes', region.postcodes)
		logDefault(CLASSNAME, 'info', 'patchRegion tags', region.tags)
		await patchData('regions', region).then(async () => {
			setDisplayRegionDialog(false)
		})
	}

	/** Regions */
	const formikRegion = useFormik({
		initialValues: { ...emptyRegion },
		validate: validateRegion,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});
	const onEditRegion = async (id) => {
		await getData('regions', id).then((region) => {
			region.postcodes = region.postcodes.map(e => e.postcode)
			region.tags = region.tags.map(e => e.alias)
			logDefault(CLASSNAME, 'info', 'onEditRegion region:', region)
			formikRegion.setValues(region);
			setDisplayRegionDialog(true)
		})
	}

	const renderContextMenu = (rowData) => {
		const items = [{ label: 'Region bearbeiten', icon: ENUM_ICONS.PENCIL, command: () => { onEditRegion(rowData.id) } },
		{ label: rowData.active ? 'Inaktiv setzen' : 'Aktiv setzen', icon: (rowData.active ? ENUM_ICONS.DEACTIVATE : ENUM_ICONS.REACTIVATE), command: () => { handleStatusChange(rowData, 'regions', 'Region') } }]

		return <div>
			<OverlayPanelMenu items={items} />
		</div>
	}

	const renderRegionTags = (row) => {
		return row.tags.map(e => <Tag key={'regiontag_' + e.id} className="mr-2 mb-1 px-3" rounded value={e.alias} />)
	}
	const renderRegionPostcodes = (row) => {
		return row.postcodes.map(e => <Tag key={'postcodetag_' + e.id} className="mr-2 mb-1 px-3" rounded value={e.postcode} />)
	}


	const getColumnsRegions = () => {
		const columns = [
			{ field: "name", sortable: true, filter: "name", header: "Name", className: '' },
		]
		if (configurationsList.eaaModules.REQUESTDISTRIBUTION) {
			columns.push({ field: "tags", sortable: true, filterField: "tagValues", filter: "tagValues", header: "Tags der Region", className: '', body: renderRegionTags });
			columns.push({ field: "postcodes", sortable: true, filterField: "postcodeValues", filter: "postcodeValues", header: "PLZ der Region", className: '', body: renderRegionPostcodes });
		}
		columns.push({ field: "active", sortable: true, filter: "active", header: "Status", className: 'w-10rem', body: 'renderTag' })
		columns.push({ className: 'w-3rem', body: renderContextMenu })
		return columns
	}
	return <div className='flex flex-column gap-2'>
		<div className='flex justify-content-end'>
			<Button className='' disabled={!isAdmin} label='Neue Region anlegen' onClick={() => { formikRegion.setValues({ ...emptyRegion }); setDisplayRegionDialog(true) }} />
		</div>
		<TemplateDataView key='datatable-regions' scrollHeight={'calc(100vh - 240px)'} classNameDataTable='flex w-full' dataTableList={regionsList} columns={getColumnsRegions()} ENUM_DATAVIEWS_VALUE={ENUM_DATAVIEWS_VALUE} />
		<EditDialog
			form={<RegionForm formik={formikRegion} key='region-form' />}
			formik={formikRegion}
			patchForm={patchRegion}
			header={formikRegion.values.id ? 'Region ändern' : 'Region neu anlegen'}
			message={formikRegion.values.id ? 'Hier können sie die Region: ' + formikRegion.values.name + ' ändern.' : 'Hier können sie eine Region neu anlegen'}
			setDisplayDialog={setDisplayRegionDialog}
			displayRegionDialog={displayRegionDialog}
		/>
	</div>
}