import { format, parse, parseISO } from "date-fns"
import { Accordion, AccordionTab } from "primereact/accordion"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { SplitButton } from "primereact/splitbutton"
import { useEffect, useState } from "react"
import { CaseEventDivider } from "../Components/CaseEventDivider"
import { ConfirmDialog } from "../Components/ConfirmDialog"
import { ENUM_ICONS } from "../Components/Icons"
import { deleteData, patchData } from "../feathers"
import { useConfigurationsList } from "../Persistence/ConfigurationsContext"
import { useCurrentUser } from "../Persistence/CurrentUserContext"
import { UserProvider } from "../Persistence/UserContext"
import logger from "../Utils/logger"
import { CaseEventsForm, defaultCaseEvent } from "./CaseEventsForm"

export const CaseEventsSheet = ({ eaaCase, caseEvents, eaaCaseUpdate }) => {
	const currentUser = useCurrentUser();
	const [activeIndex, setActiveIndex] = useState([]);
	const [formHasErrors, setFormHasErrors] = useState(true)
	const [caseEvent, setCaseEvent] = useState();
	const [caseEventToDelete, setCaseEventToDelete] = useState();
	const [displayCaseEventDialog, setDisplayCaseEventDialog] = useState(false);
	const [displayConfirmDialogDelete, setDisplayConfirmDialogDelete] = useState(false);
	const [eventClassifierStructure, setEventClassifierStructure] = useState([]);
	const [sortEventsAsc, setSortEventsAsc] = useState(false);
	const configurationsList = useConfigurationsList();

	useEffect(() => {
		if (configurationsList.CaseEventClassifierStructure) {
			setEventClassifierStructure(JSON.parse(JSON.stringify(configurationsList.CaseEventClassifierStructure)));
		}
	}, [configurationsList])

	useEffect(() => {
		let counter = 0;
		const tags = caseEvents.map(() => { return counter++ });
		setActiveIndex(tags);
	}, [caseEvents])

	const patchCaseEvent = async () => {
		caseEvent.eaaCaseId = eaaCase.id;
		caseEvent.eventDate = parse(format(caseEvent.eventTime, 'HH:mm'), 'HH:mm', caseEvent.eventDate)
		await patchData('case-events', caseEvent).then((patchedCaseEvent) => {
			if (caseEvent.reminder) {
				const reminder = {
					id: null, description: caseEvent.reminderMessage, date: new Date(), serviceName: 'eaa-cases', serviceId: eaaCase.id,
					userId: caseEvent.reminderUser.id, name: eaaCase.name + ' - ' + eaaCase.company.name, reminded: 0
				}
				patchData('reminders', reminder).catch((error) => {
					logger.error("Error  while patch reminder: " + error.message)
				})
			}

			eaaCaseUpdate();
			setDisplayCaseEventDialog(false);
		}).catch((error) => {
			logger.error('Fehler beim speichern des caseEvents:' + error.message)
		})

	}

	const handleCaseEventUpdate = (caseEvent) => {
		setCaseEvent(caseEvent);
	}


	const renderEventClassifiers = (eventClassifiers) => {

		const allItems = [];
		const eventClassifiersList = eventClassifiers;//eventClassifiers.map(eventClassifier => eventClassifier.caseEventClassifier)

		if (!eventClassifierStructure) {
			return ''
		}
		const classifierStructure = eventClassifierStructure.map((structure) => {
			structure.items.forEach((item) => allItems.push(item))
			const structureItems = structure.items.map(item => item.caseEventClassifier)
			const items = eventClassifiersList.filter(item => structureItems.includes(item.caseEventClassifier))
			return { ...structure, items }
		}).filter(structure => structure.items.length > 0)


		return classifierStructure.map((structure) => {
			const items = structure.items;
			items.sort((a, b) => {
				let x = a.caseEventClassifier.toLowerCase();
				let y = b.caseEventClassifier.toLowerCase();
				if (x < y) { return -1; }
				if (x > y) { return 1; }
				return 0;
			})
			return <div className='flex flex-column' key={'classifier_' + structure.headline}>
				<div className='flex p-0 m-0 text-600 text-base font-semibold'>
					<div>{structure.headline}</div>
				</div>

				<div className='flex flex-column p-1 m-1'>

					{items.map((item) => {
						const structureItem = allItems.find(entry => entry.caseEventClassifier === item.caseEventClassifier)
						return <div className={item.count === 0 ? 'hidden' : 'flex grid'} key={'classifier_' + item.caseEventClassifier}>
							<div className='col-fixed p-1 w-2rem'>{structureItem.fieldType === 'NUMBER' ? item.count : (item.count === 0 ? '' : <i className={ENUM_ICONS.CHECK_SQUARE}></i>)}</div>
							<div className='col p-1'>{structureItem.display} </div>
						</div>

					})}
				</div>
			</div>
		})
	}

	const onEditCaseEvent = (caseEvent) => {
		setEventClassifierStructure(JSON.parse(JSON.stringify(configurationsList.CaseEventClassifierStructure)));
		setCaseEvent(JSON.parse(JSON.stringify(caseEvent)));
		setDisplayCaseEventDialog(true);
	}
	const onDeleteCaseEvent = (caseEvent) => {
		setCaseEventToDelete(caseEvent);
		setDisplayConfirmDialogDelete(true);
	}

	const deleteCaseEvent = async () => {

		const id = caseEventToDelete.id
		setCaseEventToDelete(null);
		await deleteData('case-events', id).then(() => {
			eaaCaseUpdate();
			setDisplayConfirmDialogDelete(false);
		}).catch((error) => {
			logger.error('Error while deleteCaseEvent: ' + error.message)
		})

	}

	const handleCreateEvent = (eventType) => {
		setEventClassifierStructure(JSON.parse(JSON.stringify(configurationsList.CaseEventClassifierStructure)));
		const reminderMessage = currentUser.displayname + ' hat Ihnen eine Notiz hinterlassen!'
		const newEvent = { ...defaultCaseEvent, reminderMessage, eventType: configurationsList.EventTypeList.find(entry => entry.alias === eventType), userId: currentUser.id }
		setCaseEvent(newEvent);
		setDisplayCaseEventDialog(true)
	}

	const accordionPageHeader = (caseEvent, eventClassifiers) => {

		const eventType = caseEvent.eventType && typeof caseEvent.eventType === 'object' && caseEvent.eventType.constructor === Object ? caseEvent.eventType.display : caseEvent.eventType
		const eventDate = caseEvent.eventDate ? format(parseISO(caseEvent.eventDate), 'dd.MM.yyyy') : '';
		const eventTime = caseEvent.eventDate ? format(parseISO(caseEvent.eventDate), 'HH:mm') : '';

		return <div className="flex w-full justify-content-between m-0">
			<div className='flex w-25rem  gap-4'>
				<div className="flex font-bold w-8rem m-0 ">{`${eventType}`}</div>
				<div className="flex font-bold m-0 gap-2"><i className={ENUM_ICONS.CALENDAR} /> {` ${eventDate}`}</div>
				<div className="flex font-bold m-0 gap-2"><i className={ENUM_ICONS.CLOCK} /> {` ${eventTime}`}</div>
			</div>
			<div className='flex flex-grow-1 '>
				<div className="flex font-bold m-0"> {`Ersteller*in: ${caseEvent.creator ? caseEvent.creator.displayname : ''}`}</div>
			</div>
			<div className="flex m-0 justify-content-end gap-2">
				<i className={currentUser.permissions === 'READER' || eaaCase.status.alias === 'INACTIVE' ? 'hidden' : (ENUM_ICONS.PENCIL + 'fa-lg text-white-500')} onClick={(event) => { onEditCaseEvent(caseEvent); event.stopPropagation(); }} />
				<i className={currentUser.permissions !== 'ADMIN' && caseEvent.creator && caseEvent.creator.id !== currentUser.id ? 'hidden' : (ENUM_ICONS.TRASH + 'fa-lg text-white-500')} onClick={(event) => { onDeleteCaseEvent(caseEvent); event.stopPropagation(); }} />
			</div>
		</div>
	}

	const renderCaseEvent = (caseEvent) => {
		const eventClassifiers = caseEvent.caseEventClassifiers ? caseEvent.caseEventClassifiers : [];
		return <AccordionTab key={'caseEvent_' + caseEvent.id} headerClassName={(caseEvent.eventType.alias === 'INTERN' ? 'accordion-tab-intern' : '') + ' w-full'} header={accordionPageHeader(caseEvent, eventClassifiers)} >
			<div className="flex flex-column " >
				<div className='flex flex-column py-2'>
					<div className='flex text-600 text-base font-semibold mb-2'>
						Beschreibung
					</div>
					<div className='editor-changes-checked' dangerouslySetInnerHTML={{ __html: caseEvent.description }} />
				</div>
				{renderEventClassifiers(eventClassifiers)}
			</div>
		</AccordionTab>
	}

	const renderCaseEvents = (caseEvents) => {
		if (caseEvents) {
			caseEvents.sort((x, y) => { const a = sortEventsAsc ? x.eventDate : y.eventDate; const b = sortEventsAsc ? y.eventDate : x.eventDate; return a == b ? (y.id > x.id ? 1 : -1) : a > b ? 1 : -1 })
		} else {
			return 'keine'
		}
		const tabs = caseEvents.map((caseEvent) => {
			return renderCaseEvent(caseEvent)
		});

		return <div style={{ width: '100%' }}>
			<CaseEventDivider caseEvents={caseEvents} icon={ENUM_ICONS.PLUS} label='Neuer Kontakt' onClickHandler={handleCreateEvent} className={eaaCase.status.alias !== 'INACTIVE' ? 'my-3' : 'hidden'} />
			<div className='flex justify-content-end mb-2 gap-2'><i className="pi pi-sort-alt" onClick={() => { setSortEventsAsc(!sortEventsAsc) }} /><span>{(sortEventsAsc ? 'Aufsteigend' : 'Absteigend') + ' nach Datum'}</span></div>
			<Accordion id='accordion-caseeventsheet' multiple className='w-full' activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} >
				{tabs}
			</Accordion>
		</div>
	}

	return (
		<>
			<ConfirmDialog title='Kontakt löschen?' message={`<span>Sind Sie sicher, dass Sie <br/><b>${caseEventToDelete ? caseEventToDelete.eventType.deleteMessage : ''} vom ${caseEventToDelete ? format(parseISO(caseEventToDelete.eventDate), 'yyyy-MM-dd HH:mm') : ''}</b><br/> wirklich unwiderruflich löschen wollen?</span>`} labelOk='Ja'
				handleOnClick={deleteCaseEvent} displayConfirmDialog={displayConfirmDialogDelete} setDisplayConfirmDialog={setDisplayConfirmDialogDelete} />

			<Dialog position="center" header={caseEvent ? 'Neuer Kontakt' : 'Kontakt bearbeiten'} visible={displayCaseEventDialog} onHide={() => setDisplayCaseEventDialog(false)} id="dialog-caseevent" className='eaa-inputdialog' contentClassName="pt-2">
				<UserProvider><CaseEventsForm caseEvent={caseEvent} caseEventsParent={caseEvents} setFormHasErrors={setFormHasErrors} handleCaseEventUpdate={handleCaseEventUpdate} setShowDialog={() => { }} key='caseEventForm' /></UserProvider>
				<div className={'card'}>
					<div className="flex flex-row justify-content-end mx-4 mb-2">
						<Button className='button-cancel' label="Abbrechen" onClick={() => { setDisplayCaseEventDialog(false) }} />
						<Button onClick={patchCaseEvent} disabled={formHasErrors} className="flex-order-0 ml-3 " label={caseEvent && caseEvent.id ? 'Ändern' : 'Anlegen'} />
					</div>
				</div>
			</Dialog>
			<div className="grid m-0 caseeventssheet select-none" >
				<div className='col-12'></div>
				{renderCaseEvents(caseEvents)}
			</div>
		</>
	)

}