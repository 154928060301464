import { parseISO, startOfDay } from 'date-fns';
import { useFormik } from 'formik';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { useEffect, useState } from "react";
import { CalendarChangesChecked } from '../Components/CalendarChangesChecked';
import { DropdownChangesChecked } from '../Components/DropdownChangesChecked';
import { EditorChangesChecked } from '../Components/EditorChangesChecked';
import { InputTextareaChangesChecked } from '../Components/InputTextareaChangesChecked';
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { useCurrentUser } from '../Persistence/CurrentUserContext';
import { useOrganizationsSelectionList } from '../Persistence/OrganizationsContext';
import { useUserList } from '../Persistence/UserContext';

export const defaultCaseEvent = { id: null, version: 1, active: 1, reminderMessage: '', reminder: false, eventDate: startOfDay(new Date()), eventTime: startOfDay(new Date()), eventType: '', description: '', caseEventClassifiers: [], reminderUser: null }

export const CaseEventsForm = ({ caseEvent, caseEventsParent, handleCaseEventUpdate, setFormHasErrors, setShowDialog }) => {

	const currentUser = useCurrentUser();
	const configurationsList = useConfigurationsList();
	const organizationsSelectionList = useOrganizationsSelectionList();
	const userList = useUserList();
	const [eventType, setEventType] = useState({ alias: 'CONTACT' });
	const [organization, setOrganization] = useState(null);
	const [organizationUserList, setOrganizationUserList] = useState([])
	const [caseEventClassifierStructure, setCaseEventClassifierStructure] = useState([]);
	const [selectedClassifiers, setSelectedClassifiers] = useState([]);
	const [editmode, setEditmode] = useState(false);
	const [mounted, setMounted] = useState(false)

	const formik = useFormik({

		initialValues: { ...defaultCaseEvent, userId: currentUser.id },
		validate: (data) => {
			let errors = {};

			if (!data.eventType) {
				errors.eventType = 'Kontakt Klassifikation muss gesetzt sein';
			}
			if (!data.eventDate) {
				errors.eventDate = 'Datum muss gesetzt sein';
			}
			if (!data.description) {
				errors.description = 'Beschreibung muss ausgefüllt sein';
			}
			if (data.reminder && !data.reminderMessage) {
				errors.reminderMessage = 'Bei aktivierter Wiedervorlage muss eine Notiz eigegeben werden!';
			}
			if (data.reminder && !data.reminderUser) {
				errors.reminderUser = 'Bei aktivierter Wiedervorlage muss ein/e Bearbeiter*in eigegeben werden!';
			}
			const hasErrors = Object.keys(errors).length !== 0 ? data : null;
			setEventType(data.eventType)
			setFormHasErrors(hasErrors);
			if (!hasErrors) {
				handleCaseEventUpdate(data);
			}

			return errors;
		},

		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	useEffect(() => {
		const tempList = organization && userList ? userList.filter(entry => entry.active === 1 && entry.organizationId === organization.id) : userList;
		setOrganizationUserList(tempList);
		formik.setFieldValue('reminderUser', null)
	}, [organization, userList])

	useEffect(() => {
		setCaseEventClassifierStructure(JSON.parse(JSON.stringify(configurationsList.CaseEventClassifierStructure)))
		setSelectedClassifiers([]);
		if (caseEvent) {
			setSelectedClassifiers(caseEvent.caseEventClassifiers ? caseEvent.caseEventClassifiers : []);
			caseEvent.eventDate = ('' + caseEvent.eventDate) === 'Invalid Date' ? new Date() : caseEvent.eventDate;
			caseEvent.eventDate = ('' + parseISO(caseEvent.eventDate)) === 'Invalid Date' ? caseEvent.eventDate : parseISO(caseEvent.eventDate);
			caseEvent.eventTime = caseEvent.eventDate;
			formik.setValues(caseEvent)
			setEditmode(!caseEvent.id)
		} else if (!caseEventsParent || caseEventsParent.length === 0) {
			const newEvent = { ...defaultCaseEvent, eventType: configurationsList.EventTypeList.find(entry => entry.alias === 'CONTACT'), userId: currentUser.id }
			formik.setValues(newEvent);
			setEditmode(true)
		} else {
			formik.setValues({ ...defaultCaseEvent, userId: currentUser.id });
			handleCaseEventUpdate(formik.values)
		}
		setMounted(true);
	}, [])

	const onClassifierNumberChanged = (e, item) => {

		const count = e.value;
		const values = selectedClassifiers.slice()
		const selectedClassfier = values.find(entry => entry.caseEventClassifier === item.caseEventClassifier)
		if (selectedClassfier) {
			selectedClassfier.active = count > 0 ? 1 : 0
			selectedClassfier.count = count;
		} else if (count > 0) {
			values.push({ ...item, active: 1, count, version: 1 })
		}
		formik.setFieldValue('caseEventClassifiers', values)
		setSelectedClassifiers(values)

	}
	const onClassifierCheckboxChanged = (e) => {
		const values = selectedClassifiers.slice()
		const selectedClassfier = values.find(entry => entry.caseEventClassifier === e.value.caseEventClassifier)
		if (selectedClassfier) {
			selectedClassfier.active = e.checked ? 1 : 0
			selectedClassfier.count = e.checked ? 1 : 0
		} else if (e.checked) {
			values.push({ ...e.value, active: 1, count: 1, version: 1 })
		}
		formik.setFieldValue('caseEventClassifiers', values)
		setSelectedClassifiers(values)
	}

	const renderEventClassifiers = () => {
		const eventClassifierStructure = !formik.values.eventType ? [] : caseEventClassifierStructure.filter(entry => entry.eventType.includes(formik.values.eventType.alias))

		return eventClassifierStructure.map((structure) => {
			return <div key={'classifiers_' + structure.order}>
				<div className={structure.headline === '' ? 'hidden' : 'card'}>
					<h4>{structure.headline}</h4>
				</div>

				<div className={'card'}>
					{structure.items.map((item) => {
						const selectedClassifier = selectedClassifiers.find((entry) => item.caseEventClassifier === entry.caseEventClassifier)
						if (selectedClassifier) {
							item.count = selectedClassifier.count
							item.id = selectedClassifier.id
						}

						const checked = selectedClassifier && selectedClassifier.count === 1 && selectedClassifier.active === 1 ? true : false;
						return <div className="field field-checkbox" key={'classifiers_' + item.id + '_' + item.caseEventClassifier}>
							{item.fieldType === 'CHECKBOX' ?
								<Checkbox autoFocus={true} id={item.caseEventClassifier} key={item.caseEventClassifier} inputId={item.caseEventClassifier} name="eventClassifier" value={item} onChange={onClassifierCheckboxChanged} checked={checked} />
								: (item.fieldType === 'HEADLINE' ? "" :
									<InputNumber size={3} min={0} inputId={"classifier_" + item.caseEventClassifier} value={item.count} onValueChange={(e) => { onClassifierNumberChanged(e, item) }} />
								)
							}
							<label htmlFor={item.caseEventClassifier} className={item.fieldType === 'HEADLINE' ? 'm-0 mt-2' : "p-checkbox-label mt-2"}>{item.display} </label>
						</div>

					})}
				</div>
			</div>
		})
	}

	return (

		<div className='col' >
			{!mounted ? '' : <div className="formgrid grid">
				<div className="field col">
					<div className="formgrid grid">
						<DropdownChangesChecked id='eventType' editmode={caseEvent && !caseEvent.id && caseEventsParent && caseEventsParent.length > 0} formik={formik} itemLabel={'display'} label={'Kontakt Klassifikation'} options={configurationsList.EventTypeList} setShowDialog={setShowDialog} className='h-3rem line-height-4' />
						<CalendarChangesChecked id='eventDate' label='Datum' formik={formik} setShowDialog={setShowDialog} key='eventDate' className='field col-fixed w-10rem' classNameCalendar='h-3rem w-full' autoFocus={true} maxDate={new Date()} />
						<CalendarChangesChecked id='eventTime' label='Uhrzeit' timeOnly={true} formik={formik} setShowDialog={setShowDialog} key='eventTime' className='field col-fixed w-8rem' classNameCalendar='h-3rem w-full' autoFocus={true} />
					</div>
					<div className={(eventType.alias !== 'INTERN' || caseEvent.id ? 'hidden' : 'formgrid grid')}>
						<div className="field-checkbox ml-2">
							<Checkbox inputId="reminder" checked={formik.values.reminder} onChange={e => formik.setFieldValue('reminder', e.checked)} />
							<label htmlFor="reminder">Wiedervorlage erstellen</label>
						</div>
					</div>
					<div className={(eventType.alias !== 'INTERN' || caseEvent.id || !formik.values.reminder ? 'hidden' : 'formgrid grid')}>
						<div className='flex w-6'>
							<InputTextareaChangesChecked id='reminderMessage' formik={formik} label='Notiz der Wiedervorlage' autoFocus rows={5} className='w-full' setShowDialog={() => { }} />
						</div>
						<div className='flex w-6 flex-column '>
							<div className="field col mr-3">
								<label htmlFor='organization' className='block'>Filter Organisation</label>
								<Dropdown id="organization" className='bg-white w-full' showClear filter name="organization" optionLabel="name" value={organization} options={organizationsSelectionList} onChange={(e) => setOrganization(e.value)} autoFocus />
							</div>
							<DropdownChangesChecked filter={true} editmode={true} label={'Wiedervorlage für Bearbeiter*in'} id={'reminderUser'} itemLabel={'displayname'} formik={formik} options={organizationUserList} setShowDialog={setShowDialog} handleChange={formik.handleChange} />
						</div>
					</div>


					{renderEventClassifiers()}
					<div className="formgrid grid">
						<EditorChangesChecked id='description' formik={formik} value={formik.values.description} setValue={(value) => formik.setFieldValue('description', value)} label='Beschreibung' required={true} setShowDialog={setShowDialog} />
					</div>
				</div>
			</div>}
		</div>


	)
}
