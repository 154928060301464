import { Button } from 'primereact/button';
import { useState } from 'react';
import { TemplateDataView } from '../Components/TemplateDataView';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { useBreadcrumbsAdd } from '../Persistence/Breadcrumbs';
import { useCurrentUser } from '../Persistence/CurrentUserContext';
import { ENUM_DATAVIEWS } from '../Persistence/DataViewFilterContext';
import { useEAACasesList } from '../Persistence/EAACasesContext';
import { ReminderDialog } from '../Reminders/ReminderDialog';

const ENUM_DATAVIEWS_VALUE = ENUM_DATAVIEWS.EAACASES

export const EAACaseDataview = () => {
	const addBreadcrumb = useBreadcrumbsAdd();
	const currentUser = useCurrentUser();
	const eaaCasesList = useEAACasesList();

	const [eaaCase, setEaaCase] = useState();
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);

	const renderResponsible = (rowData) => {
		return (rowData.responsible && typeof rowData.responsible !== 'string' ? rowData.responsible.lastname + ', ' + rowData.responsible.firstname : (rowData.responsible ? rowData.responsible : 'n.n.'))
	}


	const sortStatus = (event) => {
		let data = [...eaaCasesList];
		data.sort((data1, data2) => {
			const value1 = data1[event.field];
			const value2 = data2[event.field];
			let result = null;

			if (value1 == null && value2 != null)
				result = -1;
			else if (value1 != null && value2 == null)
				result = 1;
			else if (value1 == null && value2 == null)
				result = 0;
			else if (typeof value1.alias === 'string' && typeof value2.alias === 'string')
				result = value1.alias.localeCompare(value2.alias, undefined, { numeric: true });
			else
				result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

			return (event.order * result);
		});

		return data;
	}


	const columns = [
		{ field: "displayId", sortable: true, filter: "displayId", header: "ID", className: 'w-8rem', body: null },
		{ field: "responsible", sortable: true, filter: "responsible", header: "Berater*in", className: 'w-15rem', body: renderResponsible },
		{ field: "company.name", sortable: true, filter: "company.name", header: "Unternehmen", className: '', body: (rowData) => { return rowData.company ? rowData.company.name : '' } },
		{ field: "name", sortable: true, filter: "name", header: "Name", className: 'w-20rem' },
		{ field: "millisecondsCreatedAt", sortable: true, filter: "millisecondsCreatedAt", header: "Falldatum", className: 'w-5rem', body: 'renderDate' },
		{ field: "status", sortable: true, filter: "status", header: "Status", className: 'w-6rem', body: 'renderTag' }
	]

	return (

		<div className='mx-auto eaa-dataView'>
			{!eaaCase ? '' :
				<ReminderDialog displayDialog={displayReminderDialog} setDisplayDialog={setDisplayReminderDialog} header='Fall' name={eaaCase.name + ' - ' + eaaCase.company.name} serviceId={eaaCase.id} serviceName='eaa-cases' userId={currentUser.id} />
			}

			<div className={!eaaCasesList ? 'hidden' : ''}>
				<div className="flex justify-content-end flex-wrap card-container">
					<div className="flex align-self-center justify-content-left my-2">
						<Button disabled={currentUser.permissions === 'READER'} className='flex text-white' label='Neuen Fall anlegen' onClick={() => { addBreadcrumb('Neuen Fall anlegen', `${ENUM_ROUTES.EAACASES}/new`); }} />
					</div>
				</div>

				<TemplateDataView columns={columns} showOpenLinkColumn={true} dataTableList={eaaCasesList} ENUM_DATAVIEWS_VALUE={ENUM_DATAVIEWS_VALUE} ROUTE={ENUM_ROUTES.EAACASES} />

			</div>
		</div>
	)

}