import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useState } from 'react';
import { TemplateDataView } from '../Components/TemplateDataView';
import { patchData } from '../feathers';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { useBreadcrumbsAdd } from '../Persistence/Breadcrumbs';
import { useCompaniesList } from '../Persistence/CompaniesContext';
import { useCurrentUser } from '../Persistence/CurrentUserContext';
import { ENUM_DATAVIEWS } from '../Persistence/DataViewFilterContext';
import { ReminderDialog } from '../Reminders/ReminderDialog';
import { CompanyForm } from './CompanyForm';
import { logDefault } from '../Utils/logger';

const ENUM_DATAVIEWS_VALUE = ENUM_DATAVIEWS.COMPANIES
const CLASSNAME = 'CompaniesDataview';
export const CompaniesDataview = () => {
	const addBreadcrumb = useBreadcrumbsAdd();

	const currentUser = useCurrentUser();
	const companiesList = useCompaniesList();
	const [company, setCompany] = useState();
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [displayCompanyDialog, setDisplayCompanyDialog] = useState(false);
	const [formHasErrors, setFormHasErrors] = useState();


	const patchCompany = async () => {
		company.regionId = company.region.id
		logDefault(CLASSNAME, 'info', 'company:', company)
		await patchData('companies', company, currentUser).then((company) => {
			addBreadcrumb(`${company.name}`, `${ENUM_ROUTES.COMPANIES}/` + company.id);
		})
	}

	const columns = [
		{ field: "displayId", sortable: true, filter: "displayId", header: "ID", className: 'w-8rem', body: null },
		{ field: "name", sortable: true, filter: "name", filterPlaceholder: "Unternehmen suchen", header: "Name des Unternehmens", className: '', body: null },
		{ field: "employees", header: "Mitarbeiter", className: '', align: 'right' },
		{ field: "employeesSbM", header: "SbM", className: '', align: 'right' },
		{ field: "quote", sortable: true, header: "Quote", className: '', align: 'right', body: (rowData) => { return rowData.quote.toLocaleString("de-DE", { style: "percent" }) }, },
		{ field: "millisecondsCreatedAt", sortable: true, filter: "millisecondsCreatedAt", header: "Anlagedatum", className: 'w-5rem', body: 'renderDate' },
		{ field: "region.name", sortable: true, filter: "region.name", filterPlaceholder: "Region suchen", header: "EAA-Region", className: '', body: null },
		{ field: "active", sortable: true, filter: "active", header: "Status", className: 'w-10rem', body: 'renderTag' }
	]

	return (
		<div className='mx-auto eaa-dataView select-none'>
			<Dialog header='Neues Unternehmen anlegen' visible={displayCompanyDialog} onHide={() => setDisplayCompanyDialog(false)} id="dialog-company-details" className='eaa-inputdialog' >
				<CompanyForm editmode={true} setFormHasErrors={setFormHasErrors} setCompanyForm={setCompany} handleSelectExistingCompany={() => { setDisplayCompanyDialog(false) }} setCompany={setCompany} key={'CompaniesForm'} setShowDialog={() => { }} />
				<div className="flex flex-row card-container blue-container flex-row-reverse mx-4 my-2">
					<Button type="button" className="flex-order-1 button-cancel" label='Abbrechen' onClick={() => setDisplayCompanyDialog(false)} />
					<Button onClick={patchCompany} disabled={formHasErrors} className="flex-order-0 ml-3 " label='Neu anlegen' />
				</div>
			</Dialog>
			{!company ? '' : <ReminderDialog displayDialog={displayReminderDialog} setDisplayDialog={setDisplayReminderDialog} header='Unternehmen' name={company.name} serviceId={company.id} serviceName='companies' userId={currentUser.id} />}
			<div className="flex justify-content-end flex-wrap card-container">
				<div className="flex align-self-center justify-content-left  my-2">
					<Button disabled={currentUser.permissions === 'READER'} onClick={() => { setDisplayCompanyDialog(true) }} className='flex  text-white' label='Neues Unternehmen anlegen' />
				</div>
			</div>
			<TemplateDataView columns={columns} showOpenLinkColumn={true} dataTableList={companiesList} ENUM_DATAVIEWS_VALUE={ENUM_DATAVIEWS_VALUE} ROUTE={ENUM_ROUTES.COMPANIES} />
		</div>
	)

}