import { format, parseISO } from "date-fns";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { Divider } from 'primereact/divider';
import { FieldInfo } from "../Components/FieldInfo";
import { OverlayPanelMenu } from "../Components/OverlayPanelMenu";
import { DEFAULT_EAACASES_FILTER, ENUM_DATAVIEWS, useDataViewFilterUpdate } from "../Persistence/DataViewFilterContext";
import { deleteData, getData, patchData } from "../feathers";
import { JobOpeningForm } from "../JobOpenings/JobOpeningForm";
import { ENUM_ROUTES } from "../Navigation/Routes";
import { useBreadcrumbsMinus, useBreadcrumbsUpdate } from "../Persistence/Breadcrumbs";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { useCurrentUser, useCurrentUserIsAdmin, useCurrentUserIsReader } from "../Persistence/CurrentUserContext";
import { ReminderDialog } from "../Reminders/ReminderDialog";
import logger, { logDefault } from "../Utils/logger";
import { useCallbackPrompt } from "../Utils/useCallbackPrompt";
import { classNames } from "primereact/utils";
import { CompanyForm, emptyCompany } from "./CompanyForm";
import { ENUM_ICONS } from "../Components/Icons";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import exportToPDF from "../Utils/exportToPDF";
import { ToastError } from "../Utils/ToastError";
import { CommentsModule } from "../Components/CommentsModule";
import { CompanyJobOpenings } from "./CompaniesJobOpenings";
import { CompanySheet } from "./CompanySheet";
import { RELATEDUSERSERVICECONFIG, RelatedUsersSheet, sortRelatedUser } from "../RelatedUsers/RelatedUsersSheet";
import { Link, useParams } from "react-router-dom";
import { BasicSheetLayout } from "../Components/Layouts/BasicSheetLayout";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";

export const listStatus = [{ icon: 'fa fa-check-circle-o ', display: 'Aktiv', alias: 1, bgColor: 'color-tag-green' }, { icon: 'fa fa-times-circle-o', display: 'Inaktiv', alias: 0, bgColor: 'color-tag-grey' }];
const CLASSNAME = 'CompaniesData';
export const CompaniesData = ({ companyIdParent }) => {
	const configurationsList = useConfigurationsList();
	const currentUser = useCurrentUser();
	const isAdmin = useCurrentUserIsAdmin();
	const isReader = useCurrentUserIsReader();
	const { companyId } = useParams();
	const [company, setCompany] = useState(null);
	const [items, setItems] = useState([]);
	const [jobOpening, setJobOpening] = useState();
	const [companyForm, setCompanyForm] = useState();
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const [deleteDisplayConfirmDialog, setDeleteDisplayConfirmDialog] = useState(false);
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [displayJobOpeningDialog, setDisplayJobOpeningDialog] = useState(false);
	const [companyFormHasErrors, setCompanyFormHasErrors] = useState(true);
	const [showDialog, setShowDialog] = useState(false);
	const [displayDialog, setDisplayDialog] = useState(false);
	const dataViewFilterUpdate = useDataViewFilterUpdate();
	const updateBreadcrumbs = useBreadcrumbsUpdate();
	const minusBreadcrumbs = useBreadcrumbsMinus();
	const [toastParams, setToastParams] = useState(null);
	const [active, setActive] = useState(0);
	const [readonly, setReadonly] = useState(true);

	const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);
	const [errorMessage, setErrorMessage] = useState();


	useEffect(() => {
		if (companyIdParent) {
			getData("companies", companyIdParent).then((company) => {
				setCompany(company);
				setReadonly(!company.active || isReader)
			}).catch((error) => {
				setToastParams({ title: 'Unternehmen mit der Nummer "' + companyIdParent + '" konnte nicht ermittelt werden', message: error.message })
			});
		} else if (companyId && companyId !== 'new') {
			getData("companies", companyId).then((company) => {
				setCompany(company);
				setReadonly(!company.active || isReader)
				logDefault(CLASSNAME, 'info', 'companyData:', company)
			}).catch((error) => {
				setToastParams({ title: 'Unternehmen mit der Nummer "' + companyId + '" konnte nicht ermittelt werden', message: error.message })
			});
		}

	}, [companyId])

	useEffect(() => {
		const cases = company && company.eaaCases && company.eaaCases.length > 0;
		const jobOpenings = company && company.jobOpenings && company.jobOpenings.length > 0;
		const items = isReader ? [] : [
			{ label: 'Bearbeiten', disabled: company && !company.active, icon: ENUM_ICONS.PENCIL, command: handleEditCompany },
		]
		items.push({ label: 'Exportieren', disabled: false, icon: ENUM_ICONS.EXPORT, command: handleExportCompany },
		)
		if (!isReader && company) {
			items.push({ separator: true })
			if (company.active) {
				items.push({ label: 'Inaktiv setzen', icon: ENUM_ICONS.DEACTIVATE, command: () => setDisplayConfirmDialog(true) })
			} else {
				items.push({ label: 'Aktiv setzen', icon: ENUM_ICONS.REACTIVATE, command: () => setDisplayConfirmDialog(true) })
			}
		}

		if (isAdmin && company) {
			items.push({ separator: true })
			items.push({ label: 'Unternehmen unwiderruflich löschen', className: 'eaa-menuitem-delete', icon: ENUM_ICONS.COMPANY_DELETE, disabled: (cases || jobOpenings), command: () => setDeleteDisplayConfirmDialog(true) })
		}
		setItems(items);
	}, [company])

	const onUpdateCompany = async () => {
		await getData('companies', company.id).then((company) => {
			setCompany(company);
			setReadonly(!company.active || isReader)
			setShowDialog(false);
		}).catch((error) => {
			logger.error('(CompaniesData->onUpdateCompany) Fehler beim Holen eines Unternehmens: ' + error.message)
		})
	}

	const handleSelectExistingCompany = async (companyId) => {
		await getData('companies', companyId).then((company) => {
			setCompany(company)
			setReadonly(!company.active || isReader)
			setShowDialog(false)
		}).catch((error) => {
			logger.error('(CompaniesData->handleSelectExistingCompany) Fehler beim Holen eines Unternehmens: ' + error.message)
		})

	}

	const patchCompany = async () => {
		if (companyForm.region) {
			companyForm.regionId = companyForm.region.id
		}

		await patchData('companies', companyForm, currentUser).then((patchedCompany) => {
			setCompany(patchedCompany);
			setDisplayDialog(false);
			setShowDialog(false);
		}).catch((error) => {
			logger.error('Fehler beim Speichern des Unternehmens: ' + error.message)
			setErrorMessage('Fehler beim Speichern des Unternehmens: ' + error.message)
		});
	}

	const onClickEAACases = () => {
		const comapnyName = { ...DEFAULT_EAACASES_FILTER['company.name'], value: company.name };
		const filters = { ...DEFAULT_EAACASES_FILTER, 'company.name': comapnyName }
		dataViewFilterUpdate(ENUM_DATAVIEWS.EAACASES, filters);
		updateBreadcrumbs({ home: { icon: ENUM_ICONS.EAACASE }, items: [{ label: 'Fallverwaltung', route: ENUM_ROUTES.EAACASES }] })
	}

	const handleEditCompany = () => {
		setDisplayDialog(true);
	}

	const handleExportCompany = async () => {
		await exportToPDF('COMPANY', company, null);
	}

	const toggleCompanyStatus = async () => {
		company.active = company.active ? 0 : 1;
		await patchData('companies', company).then(async () => {
			logDefault(CLASSNAME, 'info', 'company deactivated:');
			await onUpdateCompany();
		})
	}
	const deleteCompany = async () => {
		const { addresses, contactPersons } = company;
		await deleteData('companies', company.id).then(async () => {
			if (addresses) {
				for (let address of addresses) {
					await deleteData('addresses', address.id)
				}
			}
			if (contactPersons) {
				for (let contactPerson of contactPersons) {
					await deleteData(ENUM_SERVICES.COMPANYCONTACTPERSONS, contactPerson.id)
				}
			}
			minusBreadcrumbs();
		}).catch((error) => {
			setToastParams({ title: 'Unternehmen konnte nicht gelöscht werden', message: error.message })
		})
	}

	const handleUseForReminder = () => {
		setDisplayReminderDialog(true)
	}

	const patchJobOpening = async () => {
		jobOpening.eaaCaseId = (jobOpening.eaaCase ? jobOpening.eaaCase.id : jobOpening.eaaCaseId)
		await patchData('job-openings', jobOpening, currentUser).then(() => {
			onUpdateCompany()
		}).catch((error) => {
			setToastParams({ title: 'Stelle konnte nicht gespeichert werden', message: error.message })
		})
	}

	const renderDialogs = () => {
		return <>
			<ToastError toastParams={toastParams} />
			<ConfirmDialog title={'Unternehmen ' + (company.active ? 'deaktivieren' : 'reaktivieren')} message={'Wollen Sie das Unternehmen ' + (company.active ? 'deaktivieren?' : 'reaktivieren?')} labelOk='Ja'
				handleOnClick={toggleCompanyStatus} displayConfirmDialog={displayConfirmDialog} setDisplayConfirmDialog={setDisplayConfirmDialog} />
			<ConfirmDialog title={'Unternehmen unwiderruflich löschen'} message={'Wollen Sie das Unternehmen wirklich unwiderruflich löschen?\nAlle Informationen zu Adressen und Ansprechparter*innen diese Unternehmens werden auch gelöscht!'} labelOk='Ja'
				handleOnClick={deleteCompany} displayConfirmDialog={deleteDisplayConfirmDialog} setDisplayConfirmDialog={setDeleteDisplayConfirmDialog} />
			<ReminderDialog displayDialog={displayReminderDialog} setDisplayDialog={setDisplayReminderDialog} header='Unternehmen' name={company.name} serviceId={company.id} serviceName='companies' userId={currentUser.id} />
			<Dialog header='Unternehmensdetails ändern' visible={displayDialog} onHide={() => setDisplayDialog(false)} id="dialog-company-details" className='eaa-inputdialog'>
				<CompanyForm company={company} editmode={true} setFormHasErrors={setCompanyFormHasErrors} setCompanyForm={setCompanyForm} handleSelectExistingCompany={handleSelectExistingCompany} setCompany={setCompany} key={'CompaniesForm'} setShowDialog={setShowDialog} />
				<div className="flex flex-row card-container blue-container flex-row-reverse mx-4 my-2">
					<Button type="button" className="flex-order-1 button-cancel" label='Abbrechen' onClick={() => { setShowDialog(false); setDisplayDialog(false) }} />
					<Button onClick={patchCompany} disabled={companyFormHasErrors} className="flex-order-0 ml-3 " label={company && company.id ? 'Speichern' : 'Neu anlegen'} />
				</div>
			</Dialog>
			<Dialog header='Neues Stellenprofil anlegen' visible={displayJobOpeningDialog} onHide={() => setDisplayJobOpeningDialog(false)} id="dialog-jobopening-details" className="eaa-inputdialog" >
				<JobOpeningForm companyId={company.id} editmode={true} setFormHasErrors={setCompanyFormHasErrors} setJobOpening={setJobOpening} key={'JobOpeningForm'} setShowDialog={() => { }} />
				<div className="flex flex-row card-container blue-container flex-row-reverse mx-4 my-2" >
					<Button type="button" className="flex-order-1" label='Abbrechen' onClick={() => setDisplayJobOpeningDialog(false)} />
					<Button onClick={patchJobOpening} disabled={companyFormHasErrors} className="flex-order-0 ml-3 " label='Neu anlegen' />
				</div>
			</Dialog>
		</>
	}

	const renderContent = () => {
		const status = listStatus.find(entry => entry.alias === company.active);
		return <div className="eaa-maincontent flex flex-column">
			<div className='flex m-0 w-full h-full '>
				<div className='mx-auto eaa-maincontent flex flex-column'>
					<div className='flex flex-column'>
						<div className='flex justify-content-end mb-4 gap-2'>
							<Button onClick={handleEditCompany} icon={ENUM_ICONS.PENCIL} disabled={isReader} className='flex text-white color-background-lessblack' label='Bearbeiten' />
							<Button onClick={onClickEAACases} icon={ENUM_ICONS.FOLDER_OPEN_O} className='flex text-white color-background-lessblack' label='Fälle des Unternehmens anzeigen' />
							<Button onClick={handleUseForReminder} icon={ENUM_ICONS.CLOCK} disabled={isReader} className='flex text-white color-background-lessblack' label='Wiedervorlage' />
						</div>
						<div className='flex grid'>
							<div className='col flex  flex-column  text-4xl '>
								<div className='flex text-center justify-content-center font-semibold'>
									{company.name}
								</div>
								<div className='flex text-center justify-content-center text-lg m-2 gap-2'>
									<div>{'' + company.displayId + ' erstellt am ' + (company.createdAt ? format(parseISO(company.createdAt), 'yyyy-MM-dd') : '')}</div>
									<Tag className={'mr-2 text-white px-3 ' + (status.bgColor)} rounded value={status.display} />
								</div>
							</div>
						</div>
						<div className='flex card-container blue-container overflow-hidden my-3 grid'>
							<FieldInfo label='Telefon' content={company.phone} icon={<i className={ENUM_ICONS.PHONE + 'fa-2x'} />} />
							<FieldInfo label='Fax  ' content={company.fax} icon={<i className={ENUM_ICONS.FAX + 'fa-2x'} />} />
							<FieldInfo label='Mobil' content={company.mobile} icon={<i className={ENUM_ICONS.MOBILE + 'fa-3x'} />} />
							<FieldInfo label='E-Mail' content={<Link to='#' onClick={(e) => { window.location.href = `mailto:${company.mail}`; e.preventDefault(); }}>{company.mail}</Link>} icon={<i className={ENUM_ICONS.ENVELOPE + 'fa-2x'} />} />
						</div>
					</div>
					<Divider />
					<div className='flex flex-column surface-ground p-2 mx-2'>
						<div className='flex text-600 text-base mb-1'>
							Beschreibung
						</div>
						<div className='editor-changes-checked mx-4' dangerouslySetInnerHTML={{ __html: company.description }} />
					</div>
					<div className="flex flex-column mt-4 mr-3">
						<CommentsModule onUpdateComment={onUpdateCompany} key='companyCommentsModule' servicename='company-comments' readonly={readonly} parentId={company.id} idFieldname='companyId' comments={company.comments} />
					</div>
				</div>
			</div>
		</div>
	}

	const getSideSheets = () => {
		const sideSheets = [
			{ index: 0, visible: true, icon: ENUM_ICONS.COMPANY + "fa-lg", component: <CompanySheet readonly={readonly} company={company} onUpdateCompany={onUpdateCompany} hideSpecialFields={true} /> },
			{ index: 1, visible: configurationsList.eaaModules && configurationsList.eaaModules.JOBOPENING === '1', icon: ENUM_ICONS.JOBPROFILE + "fa-lg", component: <CompanyJobOpenings readonly={readonly} company={company} jobOpenings={jobOpening ? jobOpening : []} numCarousel={1} onUpdateCompany={onUpdateCompany} hideDivider={true} /> },
			{ index: 2, visible: true, icon: ENUM_ICONS.CASE_USER + "fa-lg", component: <RelatedUsersSheet relatedUsers={sortRelatedUser(company.companyUsers)} onRelatedUserPatched={onUpdateCompany} readonly={readonly} key='relatedUserSheet' parentId={company.id} relatedUserServcieConfig={RELATEDUSERSERVICECONFIG.COMPANY} hideDivider={true} /> }
		]
		return sideSheets;
	}

	return (!company ? '' :
		<BasicSheetLayout
			mainContent={<div className='flex mx-auto eaa-maincontent mt-0'>{!company ? '' : renderContent()}{!company ? '' : renderDialogs()}</div>}
			overlayMenu={<OverlayPanelMenu items={items} />}
			sideSheets={getSideSheets()}
		/>)

}
