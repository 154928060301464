import { Dialog } from "primereact/dialog";
import { ContactPersonForm } from "./ContactPersonForm";


export const ContactPersonDialog = ({ companyId, companyContactPersonId, displayDialog, setDisplayDialog, handleSave }) => {
	return (<>
		<Dialog header={companyContactPersonId ? 'Ansprechparter*in ändern' : 'Neuen Ansprechparter*in hinzufügen'} visible={displayDialog} onHide={() => setDisplayDialog(false)} id="dialog-contact-person" className='eaa-inputdialog' >
			<ContactPersonForm companyId={companyId} companyContactPersonId={companyContactPersonId} handleCancel={() => setDisplayDialog(false)} handleSave={handleSave} />
		</Dialog>
	</>
	)
}

