
import { useEffect } from 'react';
import { Calendar } from "primereact/calendar";
import { classNames } from "primereact/utils"
import { logDefault } from "../Utils/logger";
import { ENUM_ICONS } from "./Icons";

export const CalendarChangesChecked = ({ id, label, formik, className, setShowDialog, disabled, tabIndex, timeOnly, autoFocus, classNameCalendar, minDate, maxDate }) => {

	useEffect(() => {
		logDefault('CalendarChangesChecked', 'info', 'id:' + id + ' = ' + formik.values[id])
	}, [])

	const isFormFieldValid = (name) => { return formik.errors[name] };
	const getFormErrorMessage = (name) => {
		return isFormFieldValid(name) && <small className="p-error block">{formik.errors[id]}</small>;
	};

	const onChange = (e) => {
		if (setShowDialog) {
			setShowDialog(true);
		}
		formik.handleChange(e)
	}

	return (
		<div className={(className ? className : '') + ' field col mr-3'}>
			<label htmlFor={id} className='block'>{label}</label>
			<Calendar autoFocus={autoFocus} timeOnly={timeOnly} key={'calendar-date-' + id} id={id} tabIndex={tabIndex} value={formik.values[id]} onChange={onChange}
				icon={timeOnly ? ENUM_ICONS.CLOCK : ENUM_ICONS.CALENDAR} showIcon dateFormat="dd.mm.yy"
				disabled={disabled ? true : false} minDate={minDate ? minDate : null} maxDate={maxDate ? maxDate : null}
				className={classNames({ 'p-invalid': isFormFieldValid(id) }) + (classNameCalendar ? classNameCalendar : ' w-full')} />
			{getFormErrorMessage(id)}
		</div>
	)

}

