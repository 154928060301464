import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ConfirmDialog } from '../Components/ConfirmDialog';
import { ENUM_ICONS } from '../Components/Icons';
import { OverlayPanelMenu } from "../Components/OverlayPanelMenu";
import { createPassword, getData, patchData } from '../feathers';
import { ChangePassword } from '../Login/ChangePassword';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { useCurrentUser, useUserSwitchMode, useUserSwitchModeUpdate } from '../Persistence/CurrentUserContext';
import { useTaskareasList } from '../Persistence/TaskareasContext'
import { ENUM_DATAVIEWS } from '../Persistence/DataViewFilterContext';
import { useUserList } from "../Persistence/UserContext";
import { emptyUser, UserForm, validateUser } from './UserForm';
import { UserProfile } from './UserProfile';
import { useFormik } from 'formik';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { TemplateDataView } from '../Components/TemplateDataView';
import { useRegionsList } from "../Persistence/RegionsContext";

const ENUM_DATAVIEWS_VALUE = ENUM_DATAVIEWS.USERADMINISTRATION
export const UserAdministration = () => {
	const currentUser = useCurrentUser();
	const setUserSwitchMode = useUserSwitchModeUpdate();
	const userSwitchMode = useUserSwitchMode();
	const configurationsList = useConfigurationsList();
	const usersList = useUserList();
	const taskareasList = useTaskareasList();
	const regionsList = useRegionsList();
	const [user, setUser] = useState();
	const [displayUserDialog, setDisplayUserDialog] = useState(false);
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const [displayPasswordDialog, setDisplayPasswordDialog] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		if (currentUser.permissions !== 'ADMIN' && !userSwitchMode) {
			navigate('/' + ENUM_ROUTES.DASHBOARD)
		}
	}, [currentUser, userSwitchMode])

	const handleHidePasswordDialog = () => {
		setDisplayPasswordDialog(false);
	}

	const getUser = async (id) => {
		await getData('users', id).then((user) => {
			user.permissions = configurationsList.RolesList.find((entry) => entry.alias === user.permissions)
			user.mobilenumber = user.mobilenumber || '';
			user.phonenumber = user.phonenumber || '';
			user.regions = user.regions.map(e => regionsList.find(region => e.id === region.id));
			user.taskareas = user.taskareas.map(e => taskareasList.find(taskarea => e.id === taskarea.id))
			formikUser.setValues(user)
			setUser(user);
		})
	}

	const handleSelectUser = async (id) => {
		await getUser(id)
		setDisplayUserDialog(true);
	}

	const handleChangePassword = async (id) => {
		await getData('users', id).then((_user) => {
			setUser(_user);
			setDisplayPasswordDialog(true);
		})
	}
	const handleChangeUser = async (id) => {
		await getData('users', id).then((user) => {
			setUserSwitchMode(user);
		})
	}

	const renderContextMenu = (rowData) => {
		const items = [{ label: 'Nutzer ändern', icon: ENUM_ICONS.PENCIL, command: () => handleSelectUser(rowData.id), disabled: currentUser.permissions === 'READER' },];
		if (rowData.id !== currentUser.id) {
			items.push({ label: 'Passwort ändern', icon: ENUM_ICONS.PENCIL, command: () => handleChangePassword(rowData.id) });
		}

		if (rowData.active === 1) {
			items.push({ label: 'Nutzer*in inaktiv setzen', icon: ENUM_ICONS.CROSS, command: async () => { await getUser(rowData.id); setDisplayConfirmDialog(true) }, disabled: currentUser.permissions !== 'ADMIN' })
		} else {
			items.push({ label: 'Nutzer*in aktiv setzen', icon: ENUM_ICONS.CHECK, command: async () => { await getUser(rowData.id); setDisplayConfirmDialog(true) }, disabled: currentUser.permissions !== 'ADMIN' })
		}
		if (currentUser.isAdmin || userSwitchMode) {
			items.push({ label: 'Identität annehmen', icon: ENUM_ICONS.USER, command: () => handleChangeUser(rowData.id) });
		}

		return <div>
			<OverlayPanelMenu items={items} />
		</div>
	}

	const patchUser = async () => {
		formikUser.values.organizationId = formikUser.values.organization ? formikUser.values.organization.id : null;
		formikUser.values.permissions = formikUser.values.permissions.alias
		formikUser.values.displayname = formikUser.values.firstname + " " + formikUser.values.lastname
		const user = formikUser.values
		if (user.id) {
			delete user.password
		}
		await patchData('users', user).then(() => {
			setDisplayUserDialog(false);
		})
	}

	const onClickNewUser = () => {
		formikUser.setValues({ ...emptyUser, password: createPassword(false) })
		setDisplayUserDialog(true);
	}

	const formikUser = useFormik({
		initialValues: { ...emptyUser },
		validate: validateUser,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const renderEmail = (row) => {
		return <Link to='#' onClick={(e) => { window.location.href = `mailto:${row.email}`; e.preventDefault(); }}>{row.email}</Link>
	}

	const columns = [
		{ field: "organization.name", sortable: true, filter: "organization.name", header: "Organisation", className: '', body: null },
		{ field: "displayname", sortable: true, filter: "displayname", header: "Name", className: '', },
		{ field: "permissions", sortable: true, filter: "permissions", header: "Rolle", className: 'w-14rem', body: 'renderList' },
		{ field: "active", sortable: true, filter: "active", header: "Status", className: 'w-10rem', body: 'renderTag' },
		{ field: "email", filter: true, sortable: true, header: "E-Mail", className: '', body: renderEmail },
		{ sortable: false, className: 'w-3rem', body: renderContextMenu },
	]

	return (
		<div className='mx-auto eaa-dataView'>
			<ConfirmDialog title='Status ändern' message={'Nutzer: ' + (user ? user.lastname + ', ' + user.firstname : '') + (user && user.active === 0 ? ' aktivieren?' : ' deaktivieren?')} labelOk='Ja'
				handleOnClick={() => { user.active = (user.active === 1 ? 0 : 1); patchUser(user) }} displayConfirmDialog={displayConfirmDialog} setDisplayConfirmDialog={setDisplayConfirmDialog} />

			<Dialog header={'Passwort ändern: ' + (user ? user.displayname : '')} visible={displayPasswordDialog} className='eaa-passworddialog' onHide={() => setDisplayPasswordDialog(false)}>
				<ChangePassword user={user} currentUser={currentUser} hrMode={true} hideDialog={handleHidePasswordDialog} hideLogo={true} />
			</Dialog>

			<EditDialog
				form={<UserForm formik={formikUser} key='user-form' />}
				formik={formikUser}
				patchForm={patchUser}
				header={formikUser.values.id ? 'Nutzerdaten ändern' : 'Neue/r Nutzer*in hinzufügen'}
				message={formikUser.values.id ? 'Hier können sie den/die Nutzer*in: ' + formikUser.values.displayname + ' ändern.' : 'Hier können sie eine/n Nutzer*in neu anlegen'}
				setDisplayDialog={setDisplayUserDialog}
				displayRegionDialog={displayUserDialog}
			/>
			{currentUser.permissions !== 'ADMIN' && !userSwitchMode ?
				<UserProfile handleEditUser={handleSelectUser} handleChangePassword={handleChangePassword} /> :
				<>
					<div className="flex justify-content-end flex-wrap card-container">
						<div className="flexalign-self-center justify-content-left  my-2">
							<Button className='flex  text-white' label='Nutzer*in hinzufügen' onClick={onClickNewUser} />
						</div>
					</div>
					<div className="card hidden md:inline-flex w-full" >
						<TemplateDataView ENUM_DATAVIEWS_VALUE={ENUM_DATAVIEWS_VALUE} columns={columns} dataTableList={usersList} />
					</div>
				</>}
		</div>
	)
}